import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Breadcrumbs,
  Button,
  Icon,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { LayoutBase } from '../../layouts'
import { TwoFactorService } from '../../services/api/two-factor/twofactorService'
import { maskDateHour } from '../../utils/Format'
import { ModalUpdate } from './ModalUpdate'

export interface ITwoFactor {
  id: number
  masterKey: string
  link: string
  qrcode: string
  registered: boolean
  code: string
  userId: number
  createdAt?: string
  updatedAt?: string
}

export interface ITwoFactorUpdate {
  code: string
}

export const TwoFactor: React.FC = () => {
  const [twoFactor, setTwoFactor] = useState<ITwoFactor[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [twoFactorUpdate, setTwoFactorUpdate] = useState<ITwoFactor>(
    {} as ITwoFactor
  )

  const handleUpdate = (item: ITwoFactor) => {
    setTwoFactorUpdate(item)
    setIsOpenUpdate(true)
  }

  const handleGetTwoFactor = () => {
    setIsLoading(true)

    TwoFactorService.index().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        setTwoFactor(result.data)
      } else {
        toast.error('Erro ao Buscar 2FA')
      }
    })
  }

  const handleSubmit = () => {
    setIsLoading(true)
    setIsLoadingButton(true)

    TwoFactorService.create()
      .then((result) => {
        setIsLoading(false)
        setIsLoadingButton(false)

        if (result.status === 201) {
          toast.success('2FA criado com sucesso.')
        } else {
          toast.error('Erro ao criar 2FA.')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setIsLoadingButton(false)
        toast.error(err.response.data.user[0])
      })
  }

  useEffect(() => {
    handleGetTwoFactor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenUpdate, isLoadingButton])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>phonelink_lock</Icon>
          <Typography color="text.primary">Autenticação 2FA</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      <Box sx={{ marginBottom: 2 }}>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.light',
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'primary.dark'
            },
            '$:disabled': {
              backgroundColor: 'primary',
              color: 'secondary'
            }
          }}
          disabled={isLoadingButton}
        >
          <Icon>add</Icon> <Typography> Criar 2FA</Typography>
        </Button>
      </Box>

      {!isLoading && twoFactor && twoFactor.length === 0 && (
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">Nenhum registro encontrado.</Typography>
        </Box>
      )}

      {!isLoading && twoFactor && twoFactor.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>Master Key</TableCell>
                <TableCell>Registrado</TableCell>
                {twoFactor[0].registered === false && (
                  <TableCell align="center">Ações</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {twoFactor?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{maskDateHour(item.createdAt)}</TableCell>
                  <TableCell>{item.masterKey}</TableCell>
                  <TableCell>
                    {item.registered === false ? 'Não' : 'Sim'}
                  </TableCell>
                  {item.registered === false && (
                    <TableCell align="center">
                      <Button onClick={() => handleUpdate(item)}>
                        <Icon>check</Icon> Ativar
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}

      <ModalUpdate
        isOpen={isOpenUpdate}
        onClose={() => setIsOpenUpdate(false)}
        update={twoFactorUpdate}
      />
    </LayoutBase>
  )
}
