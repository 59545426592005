import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { useAuthContext } from '../../contexts'
import { LayoutBase } from '../../layouts'
import {
  IBank,
  IConsultKey,
  IStatementPix,
  PanelService
} from '../../services/api/panel/PanelServices'
import { TwoFactorService } from '../../services/api/two-factor/twofactorService'
import {
  maskCNPJ,
  maskCPF,
  maskPhone,
  maskReaisAfter
} from '../../utils/Format'
import { ITwoFactor } from '../twoFactor/TwoFactor'

const PixSchema = yup.object().shape({
  key: yup.string().min(3).max(300).required(),
  keyType: yup.string().min(3).max(50).required()
})

const SendPixEmvSchema = yup.object().shape({
  key: yup.string().min(3).max(50).required(),
  value: yup.string().min(3).max(50).required(),
  paymentId: yup.string().required(),
  name: yup.string().required(),
  document: yup.string().required(),
  ispb: yup.string().required(),
  accountNumber: yup.string().required(),
  branch: yup.string().required(),
  accountType: yup.string().required(),
  endToEndId: yup.string().required(),
  transactionIdentification: yup.string().required()
})

const SendPixSchema = yup.object().shape({
  key: yup.string().min(3).max(50).required(),
  value: yup.string().min(3).max(50).required(),
  paymentId: yup.string().required(),
  name: yup.string().required(),
  document: yup.string().required(),
  ispb: yup.string().required(),
  accountNumber: yup.string().required(),
  branch: yup.string().required(),
  accountType: yup.string().required()
})

const SendPixTedSchema = yup.object().shape({
  value: yup.string().min(3).max(50).required(),
  paymentId: yup.string().required(),
  name: yup.string().required(),
  document: yup.string().required(),
  ispb: yup.string().required(),
  accountNumber: yup.string().required(),
  branch: yup.string().required(),
  accountType: yup.string().required()
})

const CodeSchema = yup.object().shape({
  value: yup.string().min(3).max(50).required(),
  code: yup.string().min(6).max(6).required()
})

export const PixCelcoin = () => {
  const theme = useTheme()
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [keyError, setKeyError] = useState('')
  const [valueError, setValueError] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectValue, setSelectValue] = useState('')
  const [showSendPix, setShowSendPix] = useState(false)
  const [selectValueError, setSelectValueError] = useState('')
  const [dataSendPix, setDataSendPix] = useState<IStatementPix>(
    {} as IStatementPix
  )
  const [dataConsultKey, setDataConsultKey] = useState<IConsultKey>(
    {} as IConsultKey
  )
  const [twoFactor, setTwoFactor] = useState<ITwoFactor>({} as ITwoFactor)
  const [codeParts, setCodeParts] = useState(['', '', '', '', '', ''])
  const [codePartsError, setCodePartsError] = useState('')

  const [banks, setBanks] = useState<IBank[]>([])

  const [tedName, setTedName] = useState('')
  const [tedNameError, setTedNameError] = useState('')
  const [tedDocument, setTedDocument] = useState('')
  const [tedDocumentError, setTedDocumentError] = useState('')
  const [tedIspb, setTedIspb] = useState('')
  const [tedIspbError, setTedIspbError] = useState('')
  const [tedBranch, setTedBranch] = useState('')
  const [tedBranchError, setTedBranchError] = useState('')
  const [tedAccountNumber, setTedAccountNumber] = useState('')
  const [tedAccountNumberError, setTedAccountNumberError] = useState('')
  const [tedAccountType, setTedAccountType] = useState('')
  const [tedAccountTypeError, setTedAccountTypeError] = useState('')

  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  const { user } = useAuthContext()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const handleGetTwoFactor = () => {
    setIsLoading(true)

    TwoFactorService.index().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        setTwoFactor(result.data[0])
      } else {
        toast.error('Erro ao Buscar 2FA')
      }
    })
  }

  const handleGetBanks = () => {
    setIsLoading(true)

    PanelService.getBanks().then((result) => {
      setIsLoading(false)
      if (result instanceof Error) {
        toast.error('Erro ao buscar instituições financeiras.')
      } else {
        setBanks(result)
      }
    })
  }

  useEffect(() => {
    handleGetTwoFactor()
    handleGetBanks()
  }, [])

  const handleBack = () => {
    setIsError(false)
    setActiveStep(activeStep - 1)
    if (activeStep === 1) setDataConsultKey({} as IConsultKey)
  }

  const handleSubmitKey = () => {
    setIsLoading(true)
    const newKey = selectValue === 'phone' ? `+55${key}` : key
    PixSchema.validate(
      { key: newKey, keyType: selectValue },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        PanelService.consultKey(dataValidated)
          .then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              toast.error(result.message)
            } else {
              if (result.transactionAmount)
                setValue(
                  result.transactionAmount
                    .toFixed(2)
                    .replace(/[^0-9]/g, '')
                    .replace(/(\d{1,})(\d{2})$/, '$1,$2')
                )
              setDataConsultKey(result)
              setActiveStep(activeStep + 1)
            }
          })
          .catch((error) => {
            setIsLoading(false)
            toast.error(error.response.data.message)
          })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'key') {
            setKeyError(error.message)
          } else if (error.path === 'selectValue') {
            setSelectValueError(error.message)
          }
        })
      })
  }

  const formatted = () => {
    const unformattedValue = value.replace(/[^0-9]/g, '') // Remove caracteres não numéricos
    const limitedValue = unformattedValue.slice(0, 10) // Obtém os 10 primeiros dígitos
    const integerPart = limitedValue.slice(0, -2) // Obtém a parte inteira (todos os dígitos, exceto os 2 últimos)
    const decimalPart = limitedValue.slice(-2) // Obtém os 2 últimos dígitos decimais
    return `${integerPart}.${decimalPart}` // Formata a parte inteira e decimal separados por um ponto
  }

  const handleSubmitPixStatic = () => {
    setIsLoading(true)
    SendPixEmvSchema.validate(
      {
        key: dataConsultKey.key,
        name: dataConsultKey.name,
        ispb: dataConsultKey.participant.ispb,
        value: formatted(),
        branch: dataConsultKey.branch,
        document: dataConsultKey.document,
        paymentId: uuidv4(),
        accountType: dataConsultKey.accountType,
        accountNumber: dataConsultKey.accountNumber,
        endToEndId: dataConsultKey.endToEndId,
        transactionIdentification: dataConsultKey.transactionIdentification,
        provider: dataConsultKey.provider
      },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        PanelService.withdrawCelcoinStatic(dataValidated)
          .then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              toast.error(result.message)
            } else {
              toast.success('PIX realizado com sucesso!')
              setDataSendPix(result)
              setShowSendPix(true)
            }
          })
          .catch((error) => {
            setIsLoading(false)
            toast.error(error.response.data.message)
          })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'value') {
            setValueError(error.message)
          }
        })
      })
  }

  const handleSubmitPixDynamic = () => {
    setIsLoading(true)

    SendPixEmvSchema.validate(
      {
        key: dataConsultKey.key,
        name: dataConsultKey.name,
        ispb: dataConsultKey.ispb,
        value: formatted(),
        branch: dataConsultKey.branch,
        document: dataConsultKey.document,
        paymentId: uuidv4(),
        accountType: dataConsultKey.accountType,
        accountNumber: dataConsultKey.accountNumber,
        endToEndId: dataConsultKey.endToEndId,
        transactionIdentification: dataConsultKey.transactionIdentification,
        provider: dataConsultKey.provider
      },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        PanelService.withdrawCelcoinDynamic(dataValidated).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            toast.error(result.message)
          } else {
            toast.success('PIX realizado com sucesso!')
            setDataSendPix(result)
            setShowSendPix(true)
          }
        })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'value') {
            setValueError(error.message)
          }
        })
      })
  }

  const handleSubmitTed = () => {
    setIsLoading(true)

    SendPixTedSchema.validate(
      {
        name: tedName,
        ispb: tedIspb,
        value: formatted(),
        branch: tedBranch,
        document: tedDocument,
        paymentId: uuidv4(),
        accountType: tedAccountType,
        accountNumber: tedAccountNumber
      },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        PanelService.withdrawCelcoinTed(dataValidated)
          .then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              toast.error(result.message)
            } else {
              toast.success('PIX realizado com sucesso!')
              setDataSendPix(result)
              setShowSendPix(true)
            }
          })
          .catch((error) => {
            setIsLoading(false)
            toast.error(error.response.data.message)
          })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'value') {
            setValueError(error.message)
          }
        })
      })
  }

  const handleSubmitPix = () => {
    setIsLoading(true)
    const paymentId = uuidv4()
    const key = dataConsultKey.key
    const name = dataConsultKey.name
    const document = dataConsultKey.document
    const ispb = dataConsultKey.participant.ispb
    const accountNumber = dataConsultKey.accountNumber
    const branch = dataConsultKey.branch
    const accountType = dataConsultKey.accountType

    SendPixSchema.validate(
      {
        key,
        name,
        ispb,
        value: formatted(),
        branch,
        document,
        paymentId,
        accountType,
        accountNumber
      },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        PanelService.withdrawCelcoin(dataValidated).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            toast.error(result.message)
          } else {
            toast.success('PIX realizado com sucesso!')
            setDataSendPix(result)
            setShowSendPix(true)
          }
        })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'value') {
            setValueError(error.message)
          }
        })
      })
  }

  const handleSubmitCode = () => {
    setIsLoading(true)
    CodeSchema.validate(
      {
        value: formatted(),
        code: codeParts.join('')
      },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        setCodeParts(['', '', '', '', '', ''])
        TwoFactorService.update(Number(twoFactor.id), dataValidated)
          .then((result) => {
            if (result.status === 200) {
              setIsLoading(false)
              switch (dataConsultKey.emvType) {
                case '1':
                  return handleSubmitPixStatic()
                case '2':
                  return handleSubmitPixDynamic()
                default:
                  if (selectValue === 'ted') {
                    handleSubmitTed()
                  } else {
                    handleSubmitPix()
                  }
              }
            } else if (result.status === 422) {
              setIsLoading(false)
              toast.error('Código 2FA inválido.')
            } else {
              setIsLoading(false)
              toast.error('Erro inesperado.')
            }
          })
          .catch((error) => {
            setIsLoading(false)
            toast.error(error.response.data.code[0])
          })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'value') {
            setValueError(error.message)
          }
          if (error.path === 'code') {
            setCodePartsError(error.message)
          }
        })
      })
  }

  const handleTedData = () => {
    if (!tedName) {
      setTedNameError('Campo obrigatório')
      return
    }
    if (!tedDocument) {
      setTedDocumentError('Campo obrigatório')
      return
    }
    if (!tedIspb) {
      setTedIspbError('Campo obrigatório')
      return
    }
    if (!tedBranch) {
      setTedBranchError('Campo obrigatório')
      return
    }
    if (!tedAccountNumber) {
      setTedAccountNumberError('Campo obrigatório')
      return
    }
    if (!tedAccountType) {
      setTedAccountTypeError('Campo obrigatório')
      return
    }
    setActiveStep(activeStep + 1)
  }

  const handleKeyData = () => {
    if (!key) {
      setKeyError('Campo obrigatório')
      return
    }
    handleSubmitKey()
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!selectValue) {
        setSelectValueError('Campo obrigatório')
        return
      }
      if (selectValue !== 'ted') return handleKeyData()
      handleTedData()
    } else if (activeStep === 2) {
      if (twoFactor.registered === true) {
        handleSubmitCode()
      } else {
        toast.error('Autenticação de 2FA não está ativo.')
      }
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const amountFormat = (value: string) => {
    // Remove os caracteres não numéricos
    const numericValue = value.replace(/[^0-9]/g, '')

    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    return formattedValue
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value

    // Remove os caracteres não numéricos
    const numericValue = rawValue.replace(/[^0-9]/g, '')

    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setValue(formattedValue)
  }

  const handleCodeChange = (index: number, value: string) => {
    if (value.length <= 1) {
      setCodePartsError('')
      const newCodeParts = [...codeParts]
      newCodeParts[index] = value
      setCodeParts(newCodeParts)

      // Move to next input if not empty and next exists
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus()
      }
      // Move to previous input if empty and previous exists
      else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus()
      }
    }
  }

  const applyMask = (value: string) => {
    if (selectValue === 'cpf') {
      return maskCPF(value)
    } else if (selectValue === 'cnpj') {
      return maskCNPJ(value)
    } else if (selectValue === 'phone') {
      return maskPhone(value)
    }

    return value
  }

  const handleChangekeyType = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value)
    setSelectValueError('')
    if (event.target.value === 'ted') {
      setKey('')
      setKeyError('')
    } else {
      setTedName('')
      setTedNameError('')
      setTedDocument('')
      setTedDocumentError('')
      setTedIspb('')
      setTedIspbError('')
      setTedBranch('')
      setTedBranchError('')
      setTedAccountNumber('')
      setTedAccountNumberError('')
      setTedAccountType('')
      setTedAccountTypeError('')
    }
  }

  const handleChangeTedIspb = (event: SelectChangeEvent) => {
    setTedIspb(event.target.value)
    setTedIspbError('')
  }

  const handleChangeTedAccountType = (event: SelectChangeEvent) => {
    setTedAccountType(event.target.value)
    setTedAccountTypeError('')
  }

  const handleEditValue = () => {
    if (dataConsultKey.editValue === undefined) {
      if (dataConsultKey.emvType === '1') return true
      return false
    }
    if (dataConsultKey.editValue) return false
    return true
  }

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              padding: 2
            }}
          >
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}

            {!isLoading && (
              <>
                <Typography variant="h6">Pagamento PIX</Typography>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="typeKey">
                    Tipo de pagamento {!!selectValueError && 'Obrigatório'}
                  </InputLabel>
                  <Select
                    id="typeKey"
                    value={selectValue}
                    error={!!selectValueError}
                    input={<OutlinedInput label="Tipo de pagamento" />}
                    onChange={handleChangekeyType}
                    MenuProps={{
                      PaperProps: { sx: { backgroundColor: '#ffffff' } }
                    }}
                  >
                    <MenuItem value="cpf">Chave CPF</MenuItem>
                    <MenuItem value="cnpj">Chave CNPJ</MenuItem>
                    <MenuItem value="email">Chave Email</MenuItem>
                    <MenuItem value="phone">Chave Celular</MenuItem>
                    <MenuItem value="random">Chave Aleatória</MenuItem>
                    <MenuItem value="emv">Copia e Cola</MenuItem>
                    <MenuItem value="ted">Dados bancários</MenuItem>
                  </Select>
                </FormControl>
                {selectValue !== 'ted' && (
                  <TextField
                    sx={{ width: 300 }}
                    label={selectValue === 'emv' ? 'Copia e cola' : 'Chave PIX'}
                    value={applyMask(key)}
                    error={!!keyError}
                    helperText={keyError}
                    onKeyDown={() => setKeyError('')}
                    onChange={(e) => setKey(e.target.value)}
                  />
                )}
                {selectValue === 'ted' && (
                  <>
                    <TextField
                      sx={{ mb: 1, width: 300 }}
                      label="Nome do titular"
                      value={tedName}
                      error={!!tedNameError}
                      helperText={tedNameError}
                      onKeyDown={() => setTedNameError('')}
                      onChange={(e) => setTedName(e.target.value)}
                    />
                    <TextField
                      sx={{ mb: 1, width: 300 }}
                      label="Documento do titular"
                      value={
                        tedDocument.length === 11
                          ? maskCPF(tedDocument)
                          : maskCNPJ(tedDocument)
                      }
                      error={!!tedDocumentError}
                      helperText={tedDocumentError}
                      onKeyDown={() => setTedDocumentError('')}
                      onChange={(e) => setTedDocument(e.target.value)}
                    />
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="ispbBank">
                        Instituição financeira
                      </InputLabel>
                      <Select
                        id="ispbBank"
                        value={tedIspb}
                        error={!!tedIspbError}
                        input={<OutlinedInput label="Instituição financeira" />}
                        onChange={handleChangeTedIspb}
                        MenuProps={{
                          PaperProps: { sx: { backgroundColor: '#ffffff' } }
                        }}
                      >
                        {banks.map((bank) => (
                          <MenuItem key={bank.ispb} value={bank.ispb}>
                            {bank.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ mb: 1, width: 300 }}
                      label="Agência"
                      value={tedBranch}
                      error={!!tedBranchError}
                      helperText={tedBranchError}
                      onKeyDown={() => setTedBranchError('')}
                      onChange={(e) => setTedBranch(e.target.value)}
                    />
                    <TextField
                      sx={{ mb: 1, width: 300 }}
                      label="Conta com digito"
                      value={tedAccountNumber}
                      error={!!tedAccountNumberError}
                      helperText={tedAccountNumberError}
                      onKeyDown={() => setTedAccountNumberError('')}
                      onChange={(e) => setTedAccountNumber(e.target.value)}
                    />
                    <FormControl sx={{ width: 300 }}>
                      <InputLabel id="tedAccountType">Tipo de conta</InputLabel>
                      <Select
                        id="tedAccountType"
                        value={tedAccountType}
                        error={!!tedAccountTypeError}
                        input={<OutlinedInput label="Tipo de conta" />}
                        onChange={handleChangeTedAccountType}
                        MenuProps={{
                          PaperProps: { sx: { backgroundColor: '#ffffff' } }
                        }}
                      >
                        <MenuItem value="CACC">CACC - Corrente</MenuItem>
                        <MenuItem value="TRAN">TRAN - Pagamentos</MenuItem>
                        <MenuItem value="SLRY">SLRY - Salario</MenuItem>
                        <MenuItem value="SVGS">SVGS - Poupança</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </>
            )}
          </Box>
        )
      case 1:
        return (
          <>
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}

            {!isLoading && !isError && dataConsultKey && !tedAccountType && (
              <>
                <Typography variant="h6">Confirme os dados</Typography>
                <Grid
                  container
                  display="flex"
                  paddingLeft={2}
                  alignItems="left"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography>
                    <strong>Nome: </strong>
                    {dataConsultKey.name}
                  </Typography>
                  <Typography>
                    <strong>Documento: </strong>
                    {dataConsultKey?.document?.length === 11
                      ? maskCPF(dataConsultKey.document)
                      : maskCNPJ(dataConsultKey.document)}
                  </Typography>
                  <Typography>
                    <strong>Instituição: </strong>
                    {dataConsultKey?.participant?.name}
                  </Typography>
                </Grid>
              </>
            )}

            {!isLoading && !isError && tedAccountType && (
              <>
                <Typography variant="h6">Confirme os dados</Typography>
                <Grid
                  container
                  display="flex"
                  paddingLeft={2}
                  alignItems="left"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography>
                    <strong>Nome: </strong>
                    {tedName}
                  </Typography>
                  <Typography>
                    <strong>Documento: </strong>
                    {tedDocument.length === 11
                      ? maskCPF(tedDocument)
                      : maskCNPJ(tedDocument)}
                  </Typography>
                  <Typography>
                    <strong>Instituição: </strong>
                    {banks.find((bank) => bank.ispb === tedIspb)?.name}
                  </Typography>
                  <Typography>
                    <strong>Agência: </strong>
                    {tedBranch}
                  </Typography>
                  <Typography>
                    <strong>Conta: </strong>
                    {tedAccountNumber.replace(/.(?=.{1}$)/g, '$&-')}
                  </Typography>
                  <Typography>
                    <strong>Tipo de Conta: </strong>
                    {tedAccountType}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )
      case 2:
        return (
          <>
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}

            {!isLoading && (
              <>
                <Typography variant="h6" paddingLeft={2}>
                  Valor a ser pago para {dataConsultKey.name || tedName}
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  label="Valor"
                  value={value}
                  disabled={isLoading || handleEditValue()}
                  error={!!valueError}
                  helperText={valueError}
                  onKeyDown={() => setValueError('')}
                  onChange={handleValueChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>{'R$ '}</Typography>
                      </InputAdornment>
                    )
                  }}
                />
                {twoFactor?.registered === true && (
                  <Box
                    sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}
                  >
                    <Typography>Código de autenticação</Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {codeParts.map((part, index) => (
                        <TextField
                          key={index}
                          value={part}
                          error={!!codePartsError}
                          onChange={(e) =>
                            handleCodeChange(index, e.target.value)
                          }
                          inputProps={{
                            maxLength: 1,
                            style: { textAlign: 'center' }
                          }}
                          inputRef={(ref) => (inputRefs.current[index] = ref)}
                        />
                      ))}
                    </Box>
                    <Box>
                      {!!codePartsError && (
                        <Typography color="error">{codePartsError}</Typography>
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )
      default:
        return null
    }
  }

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>payments</Icon>
          <Typography color="text.primary">Celcoin</Typography>
          <Typography color="text.primary">Sacar</Typography>
          <Typography color="text.primary">PIX</Typography>
        </Breadcrumbs>
      </Box>
      <Box
        gap={2}
        marginY={2}
        padding={1}
        display="flex"
        color="text.primary"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
        width={theme.spacing(smDown ? 38 : mdDown ? 44 : 70)}
      >
        {showSendPix && dataConsultKey && (
          <>
            <Typography>Transação: {dataSendPix?.transactionId}</Typography>
            <Box>
              <Typography variant="h4">Comprovante de pagamento PIX</Typography>
              <Typography variant="button">
                {new Date().toLocaleString()}
              </Typography>
            </Box>

            <Divider sx={{ width: '100%' }} />
            <Typography variant="h4">
              {dataConsultKey.transactionAmount
                ? `R$ ${amountFormat(
                    dataConsultKey.transactionAmount.toFixed(2)
                  )}`
                : `R$ ${maskReaisAfter(value)}`}
            </Typography>
            <Divider sx={{ width: '100%' }} />

            <Grid
              container
              display="flex"
              paddingLeft={2}
              alignItems="left"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>
                <strong>De</strong>
              </Typography>
              <Typography>Li Pagamentos via {user?.name}</Typography>
              <Typography>Documento: {maskCNPJ('42310390000146')}</Typography>
              <Typography>Instituição: CELCOIN IP S.A</Typography>
              <Typography>Agência: 0001 Conta: 10054004-6</Typography>
              <Typography>Tipo de Conta: CACC</Typography>

              <Typography> </Typography>
            </Grid>
            <Grid
              container
              display="flex"
              paddingLeft={2}
              alignItems="left"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>
                <strong>Para</strong>
              </Typography>
              <Typography>
                {selectValue === 'ted' ? tedName : dataConsultKey.name}
              </Typography>
              <Typography>
                Documento:{' '}
                {selectValue === 'ted'
                  ? tedDocument.length === 11
                    ? maskCPF(tedDocument)
                    : maskCNPJ(tedDocument)
                  : dataConsultKey.document.length === 11
                  ? maskCPF(dataConsultKey.document)
                  : maskCNPJ(dataConsultKey.document)}
              </Typography>
              <Typography>
                Instituição:{' '}
                {selectValue === 'ted'
                  ? banks.find((bank) => bank.ispb === tedIspb)?.name
                  : dataConsultKey.participant.name}
              </Typography>
              <Typography>
                Agência: {tedBranch || dataConsultKey.branch} Conta:{' '}
                {tedAccountNumber.replace(/.(?=.{1}$)/g, '$&-') ||
                  dataConsultKey.accountNumber.replace(/.(?=.{1}$)/g, '$&-')}
              </Typography>
              <Typography>
                Tipo de Conta: {tedAccountType || dataConsultKey.accountType}
              </Typography>
              <Typography> </Typography>
            </Grid>

            <Divider sx={{ width: '100%' }} />

            <Grid
              container
              display="flex"
              paddingLeft={2}
              alignItems="left"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>
                <strong>ID de transação HUB</strong>
              </Typography>
              <Typography> {dataSendPix?.paymentId} </Typography>
            </Grid>
            <Grid
              container
              display="flex"
              paddingLeft={2}
              alignItems="left"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>
                <strong>ID de transação PIX</strong>
              </Typography>
              <Typography> {dataSendPix?.endToEndId} </Typography>
              <Typography> </Typography>
            </Grid>

            <Divider sx={{ width: '100%' }} />
          </>
        )}

        {!showSendPix && (
          <>
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel>Tipo de pagamento</StepLabel>
              </Step>
              <Step>
                <StepLabel>Confirmar dados</StepLabel>
              </Step>
              <Step>
                <StepLabel>Enviar Pix</StepLabel>
              </Step>
            </Stepper>

            {renderStepContent(activeStep)}

            <Grid
              container
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item display="flex" justifyContent="left" xs={6}>
                {activeStep === 0 ? (
                  ''
                ) : (
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'primary.light',
                      borderRadius: 1,
                      '&:hover': {
                        backgroundColor: 'primary.dark'
                      }
                    }}
                  >
                    Voltar
                  </Button>
                )}
              </Grid>
              <Grid item display="flex" justifyContent="right" xs={6}>
                <Button
                  variant="contained"
                  disabled={isLoading || isError}
                  onClick={handleNext}
                  endIcon={
                    isLoading ? <CircularProgress size={20} /> : undefined
                  }
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.light',
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'primary.dark'
                    }
                  }}
                >
                  {activeStep === 2 ? 'Enviar' : 'Avançar'}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </LayoutBase>
  )
}
