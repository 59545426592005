// 000.000.000-00
export const maskCPF = (cpf: string) => {
  if (cpf)
    return cpf
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

// 00.000.000/0000-00
export const maskCNPJ = (cnpj: string) => {
  if (cnpj)
    return cnpj
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

// (00) 0 0000-0000
export const maskPhone = (phone: any) => {
  if (phone)
    return phone
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '+55 ($1) $2 $3-$4')
}

// 00000-000
export const maskCEP = (cep: any) => {
  if (cep) return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2')
}

// 00/00/0000
export const maskDate = (date: any) => {
  if (date)
    return date.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
}

export const convertDate = (dateString: string) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  const formattedDate = `${day}/${month}/${year}`
  return formattedDate
}

export const maskDateFull = (dateString: any) => {
  if (!dateString) return ''
  const formattedDate = convertDate(dateString)
  return formattedDate
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
}

export const maskDateHour = (dateString: any) => {
  if (!dateString) return ''
  const originalDate = new Date(dateString)

  // Ajuste para fuso horário brasileiro (considerando horário padrão, sem considerar horário de verão)
  const brazilTimeZoneOffset = 0 * 60 // UTC-3
  const adjustedDate = new Date(
    originalDate.getTime() + brazilTimeZoneOffset * 60 * 1000
  )

  const formattedDate = adjustedDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'America/Sao_Paulo'
  })

  return formattedDate
}

// Aceita apenas que letras sejam digitadas
export const maskOnlyLetters = (value: any) => {
  if (value) return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '')
}

// Aceita apenas números
export const maskOnlyNumbers = (value: any) => {
  if (value) return value.replace(/\D/g, '')
}

//    Máscara para Moeda
export const maskReais = (value: any) => {
  if (value)
    return value.toLocaleString('brl', { style: 'currency', currency: 'BRL' })
}

export const maskReaisNumber = (value: any) => {
  if (value)
    return Number(value).toLocaleString('brl', {
      style: 'currency',
      currency: 'BRL'
    })
}

export const maskReaisAfter = (value: any) => {
  if (!value) return ''
  const numericValue = Number(value.replace(/\D/g, '')) / 100
  return numericValue.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}
