import React, { useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { useAuthContext, useDrawerContext } from '../contexts'
import { AuthService } from '../services/api/auth/AuthService'

type TLayoutBase = {
  children: React.ReactNode
}

export const LayoutBase: React.FC<TLayoutBase> = ({ children }) => {
  const { user } = useAuthContext()

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const { toggleDrawerOpen } = useDrawerContext()

  const [suspend, setSuspend] = useState(user?.suspended)

  const handleSuspend = () => {
    AuthService.handleSuspend().then((result) => {
      if (result === 200) {
        toast.success('status alterado')
        setSuspend(!suspend)
      } else {
        toast.error('erro ao alterar status')
      }
    })
  }

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent={smDown ? 'left' : 'center'}
        gap={2}
        height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}
      >
        {smDown && (
          <IconButton color="primary" onClick={toggleDrawerOpen}>
            <Icon>menu</Icon>
          </IconButton>
        )}

        <Typography
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          color="primary"
          variant={smDown ? 'h5' : mdDown ? 'h4' : 'h3'}
        >
          Olá, {user?.name}
        </Typography>
        <Button
          variant="contained"
          color={suspend ? 'success' : 'error'}
          sx={{ width: '100px' }}
          onClick={handleSuspend}
        >
          {suspend ? 'Ativar' : 'Suspender'}
        </Button>
      </Box>

      <Divider />

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ backgroundColor: 'background.default' }}
      >
        {children}
      </Box>
    </Box>
  )
}
