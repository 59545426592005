export const orderTypes = [
  'todos',
  'create payment Barcoded.',
  'pix cashin',
  'create billet',
  'pix refund',
  'create payment guide barcode.',
  'ted',
  'pix cashout celcoin',
  'pix reverted',
  'pix cashout',
  'create payment billet.'
]
