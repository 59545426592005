import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Breadcrumbs,
  Icon,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { IFees } from '../../interfaces'
import { LayoutBase } from '../../layouts'
import { FeesService } from '../../services/api/fees/FeesService'
import { maskDateHour, maskReaisNumber } from '../../utils/Format'
import { ModalCreate } from './ModalCreate'
import { ModalUpdate } from './ModalUpdate'

export const Fees: React.FC = () => {
  const [fees, setFees] = useState<IFees[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  // const [refetchDelete, setRefetchDelete] = useState(false)
  const [feesUpdate, setFeesUpdate] = useState<IFees>({} as IFees)

  // const handleDelete = (id: number) => {
  //   setIsLoading(true)
  //   FeesService.destroy(id).then((result) => {
  //     setIsLoading(false)
  //     if (result.status === 204) {
  //       toast.success('Serviço removido')
  //       setRefetchDelete(true)
  //     } else {
  //       toast.error('Erro ao remover serviço')
  //     }
  //   })
  // }

  const handleUpdate = (item: IFees) => {
    setFeesUpdate(item)
    setIsOpenUpdate(true)
  }

  useEffect(() => {
    if (isOpen || isOpenUpdate) return
    setIsLoading(true)
    FeesService.index().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        setFees(result.data)
      } else {
        toast.error('Erro ao buscar serviços')
      }
    })
  }, [isOpen, isOpenUpdate])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>percent</Icon>
          <Typography color="text.primary">Taxas</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      {/* <Box sx={{ m: 2 }}>
        <Button
          onClick={() => setIsOpen(true)}
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.light',
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'primary.dark'
            }
          }}
        >
          <Icon>add</Icon>&nbsp;Criar Taxa de Serviço
        </Button>
      </Box> */}

      {!isLoading && fees && fees.length === 0 && (
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">Nenhuma taxa encontrada.</Typography>
        </Box>
      )}

      {!isLoading && fees && fees.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>Serviço</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Custo</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fees?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{maskDateHour(item.createdAt)}</TableCell>
                  <TableCell>{item.service}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{maskReaisNumber(item.cost)}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleUpdate(item)}>
                      <Icon>edit</Icon>
                    </IconButton>
                    {/* <IconButton onClick={() => handleDelete(item.id)}>
                        <Icon>delete</Icon>
                      </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}

      <ModalCreate isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <ModalUpdate
        isOpen={isOpenUpdate}
        onClose={() => setIsOpenUpdate(false)}
        updateFees={feesUpdate}
      />
    </LayoutBase>
  )
}
