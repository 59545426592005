import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Box,
  Breadcrumbs,
  Grid,
  Icon,
  LinearProgress,
  Typography
} from '@mui/material'

import { IStatement } from '../../interfaces'
import { LayoutBase } from '../../layouts'
import { StatementService } from '../../services/api/statement/statementService'
import { maskDateHour, maskReaisNumber } from '../../utils/Format'

export const StatementDetails: React.FC = () => {
  const [statement, setStatement] = useState<IStatement>()
  const [isLoading, setIsLoading] = useState(false)

  const { IdDetails } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    if (!IdDetails) {
      toast.error('Extrato não encontrado')
      navigate('/statement')
      setIsLoading(false)
    } else {
      StatementService.show(Number(IdDetails)).then((result) => {
        setIsLoading(false)
        if (result.status === 200) {
          setStatement(result.data)
        } else {
          toast.error('Extrato não encontrado')
          navigate('/statement')
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>receipt</Icon>
          <Typography color="text.primary">Extrato</Typography>
          <Typography color="text.primary">Detalhes do Extrato</Typography>
        </Breadcrumbs>
      </Box>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}
      {!isLoading && statement && (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
          display="flex"
          justifyContent="center"
          padding={2}
          gap={2}
        >
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Typography variant="h5" textAlign="center">
              Extrato {statement.id} | Serviço {statement.service}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Criado em
              </Typography>
              <Typography variant="body2" textAlign="center">
                {maskDateHour(statement.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                ID da Transação
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.transactionId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Tipo de Operação
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.operationType === 'in' ? 'Entrada' : 'Saída'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Atualizado em
              </Typography>
              <Typography variant="body2" textAlign="center">
                {maskDateHour(statement.updatedAt)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                End to End
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.endToEndId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Valor
              </Typography>
              <Typography variant="body2" textAlign="center">
                {maskReaisNumber(statement.value)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Typography variant="h6" textAlign="center">
              Origem
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Banco
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.originBank}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Agência
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.originAgency}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Conta
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.originAccount}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Documento
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.originDocument}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Nome
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.originName}
              </Typography>
            </Grid>
          </Grid>{' '}
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Typography variant="h6" textAlign="center">
              Destino
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Banco
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.destinyBank}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Agência
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.destinyAgency}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Conta
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.destinyAccount}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Documento
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.destinyDocument}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Nome
              </Typography>
              <Typography variant="body2" textAlign="center">
                {statement.destinyName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </LayoutBase>
  )
}
