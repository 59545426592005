import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'

import { IFees } from '../../interfaces'
import { FeesService } from '../../services/api/fees/FeesService'

interface IDialog {
  isOpen: boolean
  onClose: () => void
  updateFees: IFees
}

export const ModalUpdate: React.FC<IDialog> = ({
  isOpen,
  onClose,
  updateFees
}) => {
  const [cost, setCost] = useState('')
  const [service, setService] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value
    // Certifique-se de que cost é sempre uma string
    const numericValue = rawValue.replace(/[^0-9]/g, '')
    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setCost(formattedValue)
  }

  const handleOnClose = () => {
    setCost('')
    setService('')
    setDescription('')
    onClose()
  }

  const handleSubmit = () => {
    setIsLoading(true)

    const unformattedValue = cost.replace(/[^0-9]/g, '') // Remove caracteres não numéricos
    const limitedValue = unformattedValue.slice(0, 10) // Obtém os 10 primeiros dígitos
    const integerPart = limitedValue.slice(0, -2) // Obtém a parte inteira (todos os dígitos, exceto os 2 últimos)
    const decimalPart = limitedValue.slice(-2) // Obtém os 2 últimos dígitos decimais
    const formatted = `${integerPart}.${decimalPart}` // Formata a parte inteira e decimal separados por um ponto

    const payload = {
      service,
      description,
      cost: Number(formatted)
    }

    FeesService.update(updateFees.id, payload).then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        toast.success('Serviço editado')
        handleOnClose()
      } else {
        toast.error('Erro ao editar serviço')
      }
    })
  }

  useEffect(() => {
    if (updateFees) {
      setService(updateFees.service)
      setCost(updateFees.cost?.toString())
      setDescription(updateFees.description)
    }
  }, [updateFees])

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          Editar Taxa de Serviço
          <IconButton onClick={handleOnClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 1
          }}
        >
          <TextField
            fullWidth
            label="Serviço"
            name="service"
            value={service}
            onChange={(e) => setService(e.target.value)}
          />
          <TextField
            fullWidth
            label="Descrição"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            fullWidth
            label="Custo"
            value={cost}
            onChange={handleValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>R$</Typography>
                </InputAdornment>
              )
            }}
          />
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.light',
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Editar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
