// import { useNavigate } from 'react-router-dom'

import {
  Box,
  Breadcrumbs,
  Button,
  Icon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { LayoutBase } from '../../../layouts'

export const Payments = () => {
  const theme = useTheme()
  // const navigate = useNavigate()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>payments</Icon>
          <Typography color="text.primary">Sacar</Typography>
        </Breadcrumbs>
      </Box>
      <Box
        gap={2}
        marginY={2}
        padding={1}
        display="flex"
        color="text.primary"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
        width={theme.spacing(smDown ? 38 : mdDown ? 44 : 70)}
      >
        <Button
          // onClick={() => navigate('/pix')}
          onClick={() => alert('Em manutenção')}
          sx={{
            width: smDown ? '250px' : mdDown ? '250px' : '400px',
            backgroundColor: 'primary.main',
            color: 'primary.light',
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'primary.dark'
            }
          }}
        >
          <Icon>pix</Icon>&nbsp;Enviar PIX
        </Button>
      </Box>
    </LayoutBase>
  )
}
