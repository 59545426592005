import { Dayjs } from 'dayjs'

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Icon,
  IconButton,
  Tooltip
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { IFilter } from './IFilter'

export const Filter: React.FC<IFilter> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleChangeOperationType,
  handleClearFilter,
  operationType,
  handleApplyService,
  handleExportOFX,
  isLoading,
  setIsOpen,
  service,
  fees
}) => {
  const handleChangeStartDate = (value: Dayjs | null) => {
    if (value) {
      setStartDate(value.startOf('day'))
    }
  }

  const handleChangeEndDate = (value: Dayjs | null) => {
    if (value) {
      setEndDate(value.endOf('day'))
    }
  }

  return (
    <Box
      sx={{
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        p: 2
      }}
    >
      <FormControl
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2
        }}
      >
        <DatePicker
          label="Data Inicial"
          value={startDate}
          disableFuture
          onChange={handleChangeStartDate}
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
        <DatePicker
          label="Data Final"
          value={endDate}
          disableFuture
          onChange={handleChangeEndDate}
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="label-type">Tipo de transação</InputLabel>
        <Select
          size="small"
          value={operationType}
          defaultValue="todos"
          label="Tipo de transação"
          labelId="label-type"
          onChange={handleChangeOperationType}
        >
          <MenuItem value="todos">ENTRADA/SAÍDA</MenuItem>
          <MenuItem value="in">ENTRADA</MenuItem>
          <MenuItem value="out">SAÍDA</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="label-service">Serviços</InputLabel>
        <Select
          size="small"
          value={service}
          defaultValue="todos"
          label="Serviços"
          labelId="label-service"
          onChange={handleApplyService}
        >
          <MenuItem value="todos">TODOS</MenuItem>
          <MenuItem value="PIX">PIX</MenuItem>
          {fees.map((item, index) => (
            <MenuItem key={index} value={item.service}>
              {item.service}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        startIcon={<Icon>file_download_outlined</Icon>}
        onClick={handleExportOFX}
        disabled={isLoading}
      >
        OFX
      </Button>

      {setIsOpen && (
        <Button
          fullWidth
          variant="contained"
          startIcon={<Icon>add</Icon>}
          onClick={() => setIsOpen(true)}
          disabled={isLoading}
        >
          Novo Extrato
        </Button>
      )}

      <Tooltip title="Limpar filtros">
        <IconButton
          onClick={handleClearFilter}
          disabled={isLoading}
          color="primary"
        >
          <Icon>delete_outline</Icon>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
