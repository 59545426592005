import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Icon,
  LinearProgress,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { LayoutBase } from '../../layouts'
import { PanelService } from '../../services/api/panel/PanelServices'
import { maskReaisNumber } from '../../utils/Format'

export const Celcoin = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const [endToEnd, setEndToEnd] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [receiver, setReceiver] = useState('')
  const [balance, setBalance] = useState(0)

  const handleSubmit = () => {
    setIsLoading(true)

    PanelService.celcoinConsultReceiver(endToEnd)
      .then((result) => {
        toast.success('Recebimento consultado com sucesso.')
        const data = JSON.stringify(result.body, null, 2)
        setReceiver(data)
        setEndToEnd('')
      })
      .catch(() => {
        toast.error('Erro ao consultar recebimento.')
      })
      .finally(() => setIsLoading(false))
  }

  const getBalance = useCallback(() => {
    setIsLoading(true)
    PanelService.getBalanceCelcoin().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        if (result.data.balance !== balance) {
          setBalance(result.data.balance)
        }
      } else {
        toast.error('Erro ao Buscar Saldo')
      }
    })
  }, [balance])

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>account_balance</Icon>
          <Typography color="text.primary">Celcoin</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      {!isLoading && (
        <>
          <Box
            marginY={2}
            padding={1}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            sx={{ backgroundColor: 'primary.dark', borderRadius: 1 }}
            width={theme.spacing(smDown ? 36 : mdDown ? 40 : 50)}
          >
            <Typography sx={{ color: 'primary.light' }} variant="h6">
              Saldo Disponível
            </Typography>
            <Box
              padding={1}
              sx={{ backgroundColor: 'primary.main', borderRadius: 1 }}
            >
              <Box
                gap={2}
                display="flex"
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                width={theme.spacing(smDown ? 32 : mdDown ? 36 : 46)}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    xs={10}
                  >
                    <Typography
                      sx={{ color: 'primary.light', fontSize: '24px' }}
                    >
                      <strong>{maskReaisNumber(balance)}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Button
              onClick={() => navigate('/pix-celcoin')}
              // onClick={() => alert('Em manutenção')}
              sx={{
                width: theme.spacing(smDown ? 32 : mdDown ? 36 : 46),
                marginTop: 2,
                backgroundColor: 'primary.main',
                color: 'primary.light',
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'primary.dark'
                }
              }}
            >
              <Icon>pix</Icon>&nbsp;Realizar saque
            </Button>
          </Box>
          <Box
            gap={2}
            marginY={2}
            padding={1}
            display="flex"
            color="text.primary"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
            width={theme.spacing(smDown ? 38 : mdDown ? 44 : 70)}
          >
            <Typography variant="h6" color="text.primary">
              Consultar Recebimento
            </Typography>
            <TextField
              label="End to End"
              value={endToEnd}
              onChange={(e) => setEndToEnd(e.target.value)}
              fullWidth
              size="small"
            />
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              sx={{
                backgroundColor: 'primary.main',
                color: 'primary.light',
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: 'primary.dark'
                }
              }}
            >
              Consultar
            </Button>
            {isLoading && <CircularProgress size={28} />}

            {receiver !== '' && (
              <>
                <Typography variant="h6" color="text.primary">
                  Resultado
                </Typography>
                <TextareaAutosize
                  placeholder="Payload webhook"
                  defaultValue={receiver}
                  disabled
                  style={{ width: '100%', maxWidth: '100%', resize: 'none' }}
                />
              </>
            )}
          </Box>
        </>
      )}
    </LayoutBase>
  )
}
