import './shared/utils/YupTranslation'
import 'react-toastify/dist/ReactToastify.css'
import 'dayjs/locale/pt-br'
import { BrowserRouter } from 'react-router-dom'
import { Bounce, ToastContainer } from 'react-toastify'

import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { AppRoutes } from './routes'
import { DrawerProvider } from './shared/contexts'
import { AuthProvider } from './shared/contexts/AuthContext'
import { DefaultTheme } from './shared/themes'

export const App = () => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={60000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <AuthProvider>
          <ThemeProvider theme={DefaultTheme}>
            {/* <CssBaseline /> usar isso para correção do css do mui, fazer correções de css antes de mandar para prod */}
            <DrawerProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </DrawerProvider>
          </ThemeProvider>
        </AuthProvider>
      </LocalizationProvider>
    </>
  )
}
