import { ITwoFactorUpdate } from '../../../components'
import { Api } from '../axios-config'

const index = async () => {
  const response = await Api.get('/a2fpasses')

  return response
}

const show = async (id: number) => {
  const response = await Api.get(`/a2fpasses/${id}`)

  return response
}

const create = async () => {
  const response = await Api.post('/a2fpasses')

  return response
}

const update = async (id: number, payload: ITwoFactorUpdate) => {
  const response = await Api.patch(`/a2fpasses/${id}`, payload)

  return response
}

const destroy = async (id: number) => {
  const response = await Api.delete(`/a2fpasses/${id}`)

  return response
}

export const TwoFactorService = {
  index,
  show,
  create,
  update,
  destroy
}
