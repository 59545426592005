import { Dayjs } from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

import {
  IOrder,
  IOrderIndex,
  IOrdersChart,
  IWebhook,
  IWebhookIndex,
  IWebhooksChart
} from '../../../interfaces'
import { Api } from '../axios-config'

export interface IGenerateQrCode {
  value: string
  additionalInformation?: string
}

export interface IReturnGenerateQrCode {
  transactionId: number
  transactionIdentification: string
  emv: string
  qrcodeInBase64: string
  success?: string
  message?: string
  errors?: string[]
}

export interface IReturnSendPixErrors {
  success: string
  errors: string[]
}

export interface IConsultKey {
  key: string
  keyType: string
  ispb: string
  branch: string
  accountNumber: string
  accountType: string
  personType: string
  document: string
  name: string
  transactionIdentification?: string
  transactionAmount?: number
  emvType?: string
  editValue?: boolean
  endToEndId: string
  provider: number
  participant: {
    date: string
    type: string
    name: string
    shortName: string
    startOperationDatetime: string
    ispb: string
  }
}

interface IKeyConsult {
  key: string
  keyType: string
}

export interface IStatementPix {
  paymentId: string
  transactionId: string
  endToEndId: string
}

interface ISendPix {
  paymentId: string
  name: string
  key?: string
  value: string
  document: string
  ispb: string
  accountNumber: string
  branch: string
  accountType: string
  endToEndId?: string
  transactionIdentification?: string
}

interface ITransactionInternal {
  value: string
  recipientAccount: string
}

export interface ITransactionInternalReturn {
  message: string
}

export interface IBank {
  name: string
  ispb: string
}

const generate = async (
  payload: IGenerateQrCode
): Promise<IReturnGenerateQrCode | Error> => {
  try {
    const transactionId = uuidv4()
    const newPayload = {
      ...payload,
      transactionIdentification: transactionId
        .replace(/[^A-Za-z0-9]/g, '')
        .slice(0, 25)
    }
    const { status, data } = await Api.post<IReturnGenerateQrCode>(
      '/pix/static_qrcode',
      newPayload
    )

    if (status === 200) {
      return data
    }

    return new Error('Erro ao Gerar QrCode.')
  } catch (error) {
    return new Error(
      (
        error as { response: { data: IReturnGenerateQrCode } }
      ).response.data.errors?.join('; ') || 'Erro ao Gerar QrCode.'
    )
  }
}

const consultKey = async (
  payload: IKeyConsult
): Promise<IConsultKey | Error> => {
  try {
    const { data } = await Api.post<IConsultKey>('/pix/consult_key', payload)
    if (data) {
      return data
    }

    return new Error('Chave inválida.')
  } catch (error) {
    return new Error('Chave inválida.')
  }
}

const sendPix = async (payload: ISendPix): Promise<IStatementPix | Error> => {
  try {
    const { data } = await Api.post<IStatementPix>('/pix/send_pix', payload)
    if (data) {
      return data
    }

    return new Error('Erro ao enviar PIX')
  } catch (error) {
    return new Error(
      (
        error as { response: { data: IReturnSendPixErrors } }
      ).response.data.errors.join('; ') || 'Erro ao enviar PIX.'
    )
  }
}

const transactionInternal = async (
  payload: ITransactionInternal
): Promise<ITransactionInternalReturn | Error> => {
  try {
    const { data } = await Api.post<ITransactionInternalReturn>(
      '/transfer',
      payload
    )
    if (data) {
      return data
    }

    return new Error('Erro ao realizar transferência')
  } catch (error) {
    return new Error(
      (error as { response: { data: { errors: string } } }).response.data.errors
    )
  }
}

const getOrders = async (
  page: number,
  perPage: number,
  q: string,
  startDate: Dayjs,
  endDate: Dayjs,
  orderType: string,
  orderStatus: string
) => {
  const response = await Api.get<IOrderIndex>('/order', {
    params: {
      page: page,
      perPage: perPage,
      q: q,
      startDate: startDate.subtract(3, 'hours'),
      endDate: endDate.subtract(3, 'hours'),
      orderType: orderType,
      orderStatus: orderStatus
    }
  })

  const result: IOrderIndex = {
    data: response.data.data.map((order) => {
      const newOrder = { ...order }
      if (order.status === 'paid') {
        newOrder.webhookReceived = JSON.parse(order.webhookReceived.toString())
        return newOrder
      } else {
        return order
      }
    }),
    totalCount: response.data.totalCount
  }
  return { data: result, status: response.status }
}

const getOrdersCSV = async (
  page: number,
  perPage: number,
  q: string,
  startDate: Dayjs,
  endDate: Dayjs,
  orderType: string,
  orderStatus: string
) => {
  const response = await Api.get('/order', {
    params: {
      page: page,
      perPage: perPage,
      q: q,
      startDate: startDate.subtract(3, 'hours'),
      endDate: endDate.subtract(3, 'hours'),
      orderType: orderType,
      orderStatus: orderStatus,
      csv: true
    },
    responseType: 'blob'
  })

  return response
}

const getOrder = async (id: number) => {
  const response = await Api.get<IOrder>(`/order/${id}`)
  const data = { ...response.data }
  if (data.status === 'paid')
    data.webhookReceived = JSON.parse(data.webhookReceived.toString())

  return { response, data }
}

const getOrdersChart = async () => {
  const response = await Api.get<IOrdersChart>('/order/chart')

  return response
}

const getWebhooks = async (
  page: number,
  perPage: number,
  q: string,
  startDate: Dayjs,
  endDate: Dayjs,
  webhookStatus: string,
  webhookType: string
) => {
  const response = await Api.get<IWebhookIndex>('/webhook', {
    params: {
      page: page,
      perPage: perPage,
      q: q,
      startDate: startDate.subtract(3, 'hours'),
      endDate: endDate.subtract(3, 'hours'),
      webhookStatus: webhookStatus,
      webhookType: webhookType
    }
  })
  const result: IWebhookIndex = {
    data: response.data.data.map((webhook) => {
      const newWebhook = { ...webhook }
      newWebhook.receivedPayload = JSON.parse(
        webhook.receivedPayload.toString()
      )
      return newWebhook
    }),
    totalCount: response.data.totalCount
  }
  return { data: result, status: response.status }
}

const getWebhook = async (id: number) => {
  const response = await Api.get<IWebhook>(`/webhook/${id}`)
  const data = { ...response.data }

  data.receivedPayload = JSON.parse(data.receivedPayload.toString())
  data.sendingPayload = JSON.parse(data.sendingPayload.toString())

  return { response, data }
}

const reSendWebhook = async (id: number) => {
  const response = await Api.get(`/webhook/resend/${id}`)

  return response
}

const getWebhooksChart = async () => {
  const response = await Api.get<IWebhooksChart>('/webhook/chart')

  return response
}

const celcoinConsultReceiver = async (
  endToEnd?: string,
  transactionId?: number,
  transactionIdBRCode?: number
) => {
  try {
    const { data } = await Api.get('/pix/celcoin_consult_receiver', {
      params: {
        end_to_end: endToEnd,
        transaction_id: transactionId,
        id_brcode: transactionIdBRCode
      }
    })
    if (data) {
      return data
    }

    return new Error('Erro ao consultar recebimentos da celcoin.')
  } catch (error) {
    return new Error(
      (
        error as { response: { data: IReturnSendPixErrors } }
      ).response.data.errors.join('; ') ||
        'Erro ao consultar recebimentos da celcoin.'
    )
  }
}

const getBalanceCelcoin = async () => {
  const response = await Api.get('/celcoin/balance')

  return response
}

const withdrawCelcoin = async (
  payload: ISendPix
): Promise<IStatementPix | Error> => {
  try {
    const { data } = await Api.post<IStatementPix>('/celcoin/send_pix', payload)
    if (data) {
      return data
    }

    return new Error('Erro ao enviar PIX')
  } catch (error) {
    return new Error(
      (error as { response: { data: { success: string; message: string } } })
        .response.data.message || 'Erro ao enviar PIX.'
    )
  }
}

const withdrawCelcoinTed = async (
  payload: ISendPix
): Promise<IStatementPix | Error> => {
  try {
    const { data } = await Api.post<IStatementPix>(
      '/celcoin/send_pix_ted',
      payload
    )
    if (data) {
      return data
    }

    return new Error('Erro ao enviar PIX')
  } catch (error) {
    return new Error(
      (error as { response: { data: { success: string; message: string } } })
        .response.data.message || 'Erro ao enviar PIX.'
    )
  }
}

const withdrawCelcoinStatic = async (
  payload: ISendPix
): Promise<IStatementPix | Error> => {
  try {
    const { data } = await Api.post<IStatementPix>(
      '/celcoin/send_pix_static',
      payload
    )
    if (data) {
      return data
    }

    return new Error('Erro ao enviar PIX')
  } catch (error) {
    return new Error(
      (error as { response: { data: { success: string; message: string } } })
        .response.data.message || 'Erro ao enviar PIX.'
    )
  }
}

const withdrawCelcoinDynamic = async (
  payload: ISendPix
): Promise<IStatementPix | Error> => {
  try {
    const { data } = await Api.post<IStatementPix>(
      '/celcoin/send_pix_dynamic',
      payload
    )
    if (data) {
      return data
    }

    return new Error('Erro ao enviar PIX')
  } catch (error) {
    return new Error(
      (error as { response: { data: { success: string; message: string } } })
        .response.data.message || 'Erro ao enviar PIX.'
    )
  }
}

const getBanks = async (): Promise<IBank[] | Error> => {
  try {
    const { data, status } = await Api.get('/banks')
    if (status === 200) {
      return data
    }

    return new Error('Erro ao buscar instituições financeiras.')
  } catch (error) {
    return new Error(
      (error as { response: { data: { success: string; message: string } } })
        .response.data.message || 'Erro ao buscar instituições financeiras.'
    )
  }
}

export const PanelService = {
  sendPix,
  generate,
  consultKey,
  transactionInternal,
  getOrders,
  getOrdersCSV,
  getOrder,
  getOrdersChart,
  getWebhooks,
  getWebhook,
  reSendWebhook,
  getWebhooksChart,
  celcoinConsultReceiver,
  getBalanceCelcoin,
  withdrawCelcoin,
  withdrawCelcoinStatic,
  withdrawCelcoinDynamic,
  getBanks,
  withdrawCelcoinTed
}
