import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

import { Environment } from '../../../environment'
import {
  errorInterceptor,
  responseInterceptor,
  requestInterceptor
} from './interceptors'

const Api = applyCaseMiddleware(
  axios.create({
    baseURL: Environment.URL_BASE
  })
)

Api.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error)
)

Api.interceptors.request.use((request) => requestInterceptor(request))
export { Api }
