import { createTheme } from '@mui/material'

export const DefaultTheme = createTheme({
  palette: {
    primary: {
      main: '#142249',
      dark: '#1d3062',
      light: '#ffffff',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#DDDDDD',
      dark: '#AAAAAA',
      light: '#AAAAAA',
      contrastText: '#AAAAAA'
    },
    background: {
      default: '#FFFFFF',
      paper: '#DDDDDD'
    },
    text: {
      primary: '#142249',
      secondary: '#142249'
    }
  }
})
