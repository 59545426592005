import { ILimit, ILimitUpdate } from '../../../interfaces/limits'
import { Api } from '../axios-config'

const index = async () => {
  const response = await Api.get<ILimit[]>('/cashout_limits')

  return response
}

const update = async (id: number, payload: ILimitUpdate) => {
  const response = await Api.patch<ILimitUpdate>(
    `/cashout_limits/${id}`,
    payload
  )

  return response
}

export const LimitsService = {
  index,
  update
}
