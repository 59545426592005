import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import dayjs, { Dayjs } from 'dayjs'

import {
  Box,
  Breadcrumbs,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { IOrder } from '../../../interfaces'
import { LayoutBase } from '../../../layouts'
import { PanelService } from '../../../services/api/panel/PanelServices'
import { maskDateHour, maskReaisNumber } from '../../../utils/Format'
import { Filter } from './Filter'

export const Order: React.FC = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [orders, setOrders] = useState<IOrder[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(30, 'days').startOf('day')
  )
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('day'))
  const [orderType, setOrderType] = useState('todos')
  const [orderStatus, setOrderStatus] = useState('todos')

  const navigate = useNavigate()

  const handleClick = (id: number) => {
    navigate(`/orders/${id}`)
  }

  const handleGetOrders = () => {
    setIsLoading(true)

    PanelService.getOrders(
      page,
      perPage,
      searchValue,
      startDate,
      endDate,
      orderType,
      orderStatus
    ).then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        setOrders(result.data.data)
        setTotalCount(Number(result.data.totalCount))
        if (Math.ceil(result.data.totalCount / perPage) < page) {
          setPage(1)
        }
        if (searchValue.length > 0) {
          setSearchValue('')
        }
      } else {
        toast.error('Erro ao Buscar Ordens')
      }
    })
  }

  const handleChangeOrderType = (e: SelectChangeEvent) => {
    setOrderType(e.target.value as string)
  }

  const handleChangeOrderStatus = (e: SelectChangeEvent) => {
    setOrderStatus(e.target.value as string)
  }

  const handleClearFilters = () => {
    setStartDate(dayjs().subtract(30, 'days'))
    setEndDate(dayjs())
    setSearchValue('')
    setOrderType('todos')
    setOrderStatus('todos')
  }

  const handleGetOrdersCSV = () => {
    if (orders.length === 0) return toast.error('Nenhuma ordem encontrada.')
    setIsLoading(true)

    PanelService.getOrdersCSV(
      page,
      perPage,
      searchValue,
      startDate,
      endDate,
      orderType,
      orderStatus
    )
      .then((result) => {
        if (result.status === 200) {
          // const contentDisposition = result.headers['contentDisposition']
          const contentDisposition = result.headers['content-disposition']
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          const fileName = fileNameMatch ? fileNameMatch[1] : 'orders.csv'
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          toast.error('Erro ao Exportar CSV')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    handleGetOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, startDate, endDate, orderType, orderStatus])

  return (
    <LayoutBase>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>receipt_long</Icon>
          <Typography color="text.primary">Ordens</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '95%' }}
        p={2}
      >
        <Filter
          startDate={startDate}
          setStartDate={(value) => setStartDate(value)}
          endDate={endDate}
          setEndDate={(value) => setEndDate(value)}
          searchValue={searchValue}
          setSearchValue={(value) => setSearchValue(value)}
          isLoading={isLoading}
          handleGetOrders={handleGetOrders}
          orderType={orderType}
          handleChangeOrderType={handleChangeOrderType}
          orderStatus={orderStatus}
          handleChangeOrderStatus={handleChangeOrderStatus}
          handleClearFilters={handleClearFilters}
          handleExportCSV={handleGetOrdersCSV}
        />
      </Box>

      {!isLoading && orders && orders.length === 0 && (
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">Nenhuma ordem encontrada.</Typography>
        </Box>
      )}

      {totalCount > 0 && totalCount > perPage && !isLoading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '95%' }}
          p={2}
        >
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(_, newPage) => setPage(newPage)}
            siblingCount={5}
            boundaryCount={10}
          />
          <FormControl>
            <InputLabel id="label-perpage">Por página</InputLabel>
            <Select
              labelId="label-perpage"
              value={perPage}
              label="Por página"
              onChange={(e) => setPerPage(Number(e.target.value))}
              sx={{ width: '100px' }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      {!isLoading && orders && orders.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>END TO END</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Ver mais</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{maskDateHour(item.createdAt)}</TableCell>
                  <TableCell>{item.orderId}</TableCell>
                  <TableCell>{item.webhookReceived.EndToEndId}</TableCell>
                  <TableCell>{item.orderType}</TableCell>
                  <TableCell>
                    {item.status === 'paid' && 'Pago'}
                    {item.status === 'pending' && 'Pendente'}
                    {item.status === 'cancelled' && 'Cancelado'}
                  </TableCell>
                  <TableCell>{maskReaisNumber(item.value)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClick(item.id)}>
                      <Icon>read_more</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}

      {totalCount > 0 && totalCount > perPage && !isLoading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '95%' }}
          p={2}
        >
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(_, newPage) => setPage(newPage)}
            siblingCount={5}
            boundaryCount={10}
          />
          <FormControl>
            <InputLabel id="label-perpage">Por página</InputLabel>
            <Select
              labelId="label-perpage"
              value={perPage}
              label="Por página"
              onChange={(e) => setPerPage(Number(e.target.value))}
              sx={{ width: '100px' }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </LayoutBase>
  )
}
