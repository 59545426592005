import { useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'

import { StatementService } from '../../services/api/statement/statementService'

interface IDialog {
  isOpen: boolean
  onClose: () => void
}

export const ModalCreate: React.FC<IDialog> = ({ isOpen, onClose }) => {
  const [value, setValue] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [operationType, setOperationType] = useState('')
  const [endToEndId, setEndToEndId] = useState('')
  const [originName, setOriginName] = useState('')
  const [originDocument, setOriginDocument] = useState('')
  const [originAgency, setOriginAgency] = useState('')
  const [originAccount, setOriginAccount] = useState('')
  const [originBank, setOriginBank] = useState('')
  const [destinyName, setDestinyName] = useState('')
  const [destinyDocument, setDestinyDocument] = useState('')
  const [destinyAgency, setDestinyAgency] = useState('')
  const [destinyAccount, setDestinyAccount] = useState('')
  const [destinyBank, setDestinyBank] = useState('')
  const [service, setService] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value
    // Remove os caracteres não numéricos
    const numericValue = rawValue.replace(/[^0-9]/g, '')
    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setValue(formattedValue)
  }

  const handleOnClose = () => {
    setValue('')
    setTransactionId('')
    setOperationType('')
    setEndToEndId('')
    setOriginName('')
    setOriginDocument('')
    setOriginAgency('')
    setOriginAccount('')
    setOriginBank('')
    setDestinyName('')
    setDestinyDocument('')
    setDestinyAgency('')
    setDestinyAccount('')
    setDestinyBank('')
    setService('')
    onClose()
  }

  const handleSubmit = () => {
    setIsLoading(true)
    const unformattedValue = value.replace(/[^0-9]/g, '') // Remove caracteres não numéricos
    const limitedValue = unformattedValue.slice(0, 10) // Obtém os 10 primeiros dígitos
    const integerPart = limitedValue.slice(0, -2) // Obtém a parte inteira (todos os dígitos, exceto os 2 últimos)
    const decimalPart = limitedValue.slice(-2) // Obtém os 2 últimos dígitos decimais
    const formatted = `${integerPart}.${decimalPart}` // Formata a parte inteira e decimal separados por um ponto

    const payload = {
      transactionId,
      operationType,
      endToEndId,
      originName,
      originDocument,
      originAgency,
      originAccount,
      originBank,
      destinyName,
      destinyDocument,
      destinyAgency,
      destinyAccount,
      destinyBank,
      service,
      value: Number(formatted)
    }

    StatementService.create(payload).then((result) => {
      setIsLoading(false)
      if (result.status === 201) {
        toast.success('Extrato criado')
        handleOnClose()
      } else {
        toast.error('Erro ao criar extrato')
      }
    })
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          Criar Extrato
          <IconButton onClick={handleOnClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 1
          }}
        >
          <TextField
            fullWidth
            label="ID Transação"
            name="transactionId"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
          />
          <TextField
            fullWidth
            label="Tipo de Operação"
            name="operationType"
            value={operationType}
            onChange={(e) => setOperationType(e.target.value)}
          />
          <TextField
            fullWidth
            label="Serviço"
            name="service"
            value={service}
            onChange={(e) => setService(e.target.value)}
          />
          <TextField
            fullWidth
            label="Valor"
            value={value}
            onChange={handleValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>R$</Typography>
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            label="End to End"
            name="endToEndId"
            value={endToEndId}
            onChange={(e) => setEndToEndId(e.target.value)}
          />
          <Grid
            container
            display="flex"
            flexDirection="row"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Grid
              container
              item
              gap={1}
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              paddingRight={1}
            >
              <TextField
                fullWidth
                label="Origem"
                name="originName"
                value={originName}
                onChange={(e) => setOriginName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Documento de Origem"
                name="originDocument"
                value={originDocument}
                onChange={(e) => setOriginDocument(e.target.value)}
              />
              <TextField
                fullWidth
                label="Agência de Origem"
                name="originAgency"
                value={originAgency}
                onChange={(e) => setOriginAgency(e.target.value)}
              />
              <TextField
                fullWidth
                label="Conta de Origem"
                name="originAccount"
                value={originAccount}
                onChange={(e) => setOriginAccount(e.target.value)}
              />
              <TextField
                fullWidth
                label="Banco de Origem"
                name="originBank"
                value={originBank}
                onChange={(e) => setOriginBank(e.target.value)}
              />
            </Grid>
            <Grid
              container
              item
              gap={1}
              xs={12}
              sm={5}
              md={6}
              lg={6}
              xl={6}
              paddingLeft={1}
            >
              <TextField
                fullWidth
                label="Destino"
                name="destinyName"
                value={destinyName}
                onChange={(e) => setDestinyName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Documento de Destino"
                name="destinyDocument"
                value={destinyDocument}
                onChange={(e) => setDestinyDocument(e.target.value)}
              />
              <TextField
                fullWidth
                label="Agência de Destino"
                name="destinyAgency"
                value={destinyAgency}
                onChange={(e) => setDestinyAgency(e.target.value)}
              />
              <TextField
                fullWidth
                label="Conta de Destino"
                name="destinyAccount"
                value={destinyAccount}
                onChange={(e) => setDestinyAccount(e.target.value)}
              />
              <TextField
                fullWidth
                label="Banco de Destino"
                name="destinyBank"
                value={destinyBank}
                onChange={(e) => setDestinyBank(e.target.value)}
              />
            </Grid>
          </Grid>

          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.light',
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Criar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
