import { AxiosResponse } from 'axios'

import { LOCAL_STORAGE_USER_DATA } from '../../../../contexts'

const clearLocalStorage = () => {
  localStorage.removeItem('Authorization')
  localStorage.removeItem(LOCAL_STORAGE_USER_DATA)
}

export const responseInterceptor = (response: AxiosResponse) => {
  if (response.config.url === '/auth/sign_in') {
    localStorage.setItem('Authorization', response.headers['authorization'])
  }

  if (response.config.url === '/auth/sign_out') {
    clearLocalStorage()
  }

  if (response.status === 401) {
    clearLocalStorage()
  }

  return response
}
