import { AxiosError } from 'axios'

import { LOCAL_STORAGE_USER_DATA } from '../../../../contexts'

export const errorInterceptor = (error: AxiosError) => {
  if (error.code === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'))
  }

  if (error.response?.status === 401) {
    localStorage.removeItem('Authorization')
    localStorage.removeItem(LOCAL_STORAGE_USER_DATA)

    return Promise.reject(error)
  }

  return Promise.reject(error)
}
