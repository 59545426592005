import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import dayjs, { Dayjs } from 'dayjs'

import {
  Box,
  Breadcrumbs,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { IFees, IStatement, IStatementIndex } from '../../interfaces'
import { LayoutBase } from '../../layouts'
import { FeesService } from '../../services/api/fees/FeesService'
import { StatementService } from '../../services/api/statement/statementService'
import { maskDateHour, maskReaisNumber } from '../../utils/Format'
import { Filter } from './Filter'
import { ModalCreate } from './ModalCreate'
import { ModalUpdate } from './ModalUpdate'

export const Statement: React.FC = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [statements, setStatements] = useState<IStatementIndex>(
    {} as IStatementIndex
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [statementsUpdate, setStatementsUpdate] = useState<IStatement>(
    {} as IStatement
  )
  const [service, setService] = useState<string>('todos')
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(30, 'days').startOf('day')
  )
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('day'))
  const [operationType, setOperationType] = useState<string>('todos')
  const [fees, setFees] = useState<IFees[]>([])

  const navigate = useNavigate()

  const handleClick = (id: number) => {
    navigate(`/statement/${id}`)
  }

  const handleUpdate = (item: IStatement) => {
    setStatementsUpdate(item)
    setIsOpenUpdate(true)
  }

  const handleGetStatement = (
    serviceName: string = service,
    opType: string = operationType
  ) => {
    setIsLoading(true)
    StatementService.index(
      serviceName,
      page,
      perPage,
      startDate,
      endDate,
      opType
    ).then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        setStatements(result.data)
        setTotalCount(Number(result.data.totalCount))
        if (Math.ceil(result.data.totalCount / perPage) < page) {
          setPage(1)
        }
      } else {
        toast.error('Erro ao Buscar Extrato')
      }
    })
  }

  const handleGetFees = () => {
    setIsLoading(true)

    FeesService.index().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        setFees(result.data)
      } else {
        toast.error('Erro ao buscar serviços')
      }
    })
  }

  const handleApplyService = (e: SelectChangeEvent) => {
    setService(e.target.value as string)
    handleGetStatement(e.target.value as string, operationType)
  }

  const handleChangeOperationType = (e: SelectChangeEvent) => {
    setOperationType(e.target.value as string)
    handleGetStatement(service, e.target.value as string)
  }

  const handleClearFilter = () => {
    setStartDate(dayjs().subtract(30, 'days'))
    setEndDate(dayjs())
    setService('todos')
    setOperationType('todos')
  }

  const handleExportOFX = () => {
    setIsLoading(true)
    StatementService.ofx(
      service,
      page,
      perPage,
      startDate,
      endDate,
      operationType
    ).then((result) => {
      setIsLoading(false)
      if (result.status === 202) {
        toast.success(result.data.message)
      } else {
        toast.error('Erro ao gerar OFX')
      }
    })
  }

  useEffect(() => {
    handleGetFees()
  }, [])

  useEffect(() => {
    handleGetStatement()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, isOpen, isOpenUpdate, startDate, endDate])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>receipt</Icon>
          <Typography color="text.primary">Extratos</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      <Filter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        service={service}
        fees={fees}
        operationType={operationType}
        handleChangeOperationType={handleChangeOperationType}
        handleApplyService={handleApplyService}
        isLoading={isLoading}
        handleExportOFX={handleExportOFX}
        // setIsOpen={setIsOpen}
        handleClearFilter={handleClearFilter}
      />

      {!isLoading && statements && statements.data?.length === 0 && (
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">Nenhum registro encontrado.</Typography>
        </Box>
      )}

      {totalCount > 0 && totalCount > perPage && !isLoading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '95%' }}
          p={2}
        >
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(_, newPage) => setPage(newPage)}
            siblingCount={5}
            boundaryCount={10}
          />
          <FormControl>
            <InputLabel id="label-perpage">Por página</InputLabel>
            <Select
              labelId="label-perpage"
              value={perPage}
              label="Por página"
              onChange={(e) => setPerPage(Number(e.target.value))}
              sx={{ width: '100px' }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      {!isLoading && statements && statements.data?.length > 0 && (
        <Box
          sx={{
            width: '95%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            p: 2
          }}
        >
          <Typography variant="button" color="#0F0">
            Entrada: {maskReaisNumber(statements?.totalAmountIn)}
          </Typography>
          <Typography variant="button" color="#F00">
            Saída: {maskReaisNumber(statements?.totalAmountOut)}
          </Typography>
          <Typography
            variant="button"
            color={
              statements?.totalAmountIn - statements?.totalAmountOut >= 0
                ? '#0F0'
                : '#F00'
            }
          >
            Saldo no período:{' '}
            {maskReaisNumber(
              statements?.totalAmountIn - statements?.totalAmountOut
            )}
          </Typography>
          <Typography variant="button">
            Total de registros: {totalCount}
          </Typography>
        </Box>
      )}

      {!isLoading && statements && statements.data?.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>Origem</TableCell>
                <TableCell>Destino</TableCell>
                <TableCell>ID da TRansação</TableCell>
                <TableCell>Serviço</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>End to End</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements.data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{maskDateHour(item.createdAt)}</TableCell>
                  <TableCell>{item.originName}</TableCell>
                  <TableCell>{item.destinyName}</TableCell>
                  <TableCell>{item.transactionId}</TableCell>
                  <TableCell>{item.service}</TableCell>
                  <TableCell>
                    {item.operationType === 'in' ? (
                      <Typography color="#0F0">
                        {maskReaisNumber(item.value)}
                      </Typography>
                    ) : (
                      <Typography color="#F00">
                        {maskReaisNumber(item.value)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{item.endToEndId}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleUpdate(item)}>
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => handleClick(item.id)}>
                      <Icon>read_more</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}

      {totalCount > 0 && totalCount > perPage && !isLoading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '95%' }}
          p={2}
        >
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(_, newPage) => setPage(newPage)}
            siblingCount={5}
            boundaryCount={10}
          />
          <FormControl>
            <InputLabel id="label-perpage">Por página</InputLabel>
            <Select
              labelId="label-perpage"
              value={perPage}
              label="Por página"
              onChange={(e) => setPerPage(Number(e.target.value))}
              sx={{ width: '100px' }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      <ModalCreate isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <ModalUpdate
        isOpen={isOpenUpdate}
        onClose={() => setIsOpenUpdate(false)}
        update={statementsUpdate}
      />
    </LayoutBase>
  )
}
