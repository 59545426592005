import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import dayjs, { Dayjs } from 'dayjs'

import {
  Box,
  Breadcrumbs,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { IWebhook } from '../../interfaces'
import { LayoutBase } from '../../layouts'
import { PanelService } from '../../services/api/panel/PanelServices'
import { maskDateHour } from '../../utils/Format'
import { Filter } from './Filter'

export const Webhook: React.FC = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [webhooks, setWebhooks] = useState<IWebhook[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(30, 'days').startOf('day')
  )
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('day'))
  const [webhookType, setWebhookType] = useState('todos')
  const [webhookStatus, setWebhookStatus] = useState('todos')

  const navigate = useNavigate()

  const handleClick = (id: number) => {
    navigate(`/webhooks/${id}`)
  }

  const handleGetWebhooks = () => {
    setIsLoading(true)

    PanelService.getWebhooks(
      page,
      perPage,
      searchValue,
      startDate,
      endDate,
      webhookStatus,
      webhookType
    ).then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        setWebhooks(result.data.data)
        setTotalCount(Number(result.data.totalCount))
        if (Math.ceil(result.data.totalCount / perPage) < page) {
          setPage(1)
        }
        if (searchValue.length > 0) {
          setSearchValue('')
        }
      } else {
        toast.error('Erro ao Buscar Webhooks')
      }
    })
  }

  const handleChangeWebhookType = (e: SelectChangeEvent) => {
    setWebhookType(e.target.value as string)
  }

  const handleChangeWebhookStatus = (e: SelectChangeEvent) => {
    setWebhookStatus(e.target.value as string)
  }

  const handleClearFilters = () => {
    setStartDate(dayjs().subtract(30, 'days'))
    setEndDate(dayjs())
    setSearchValue('')
    setWebhookType('todos')
    setWebhookStatus('todos')
  }

  useEffect(() => {
    handleGetWebhooks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, startDate, endDate, webhookType, webhookStatus])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>webhook</Icon>
          <Typography color="text.primary">Webhooks</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '95%' }}
        p={2}
      >
        <Filter
          startDate={startDate}
          setStartDate={(value) => setStartDate(value)}
          endDate={endDate}
          setEndDate={(value) => setEndDate(value)}
          searchValue={searchValue}
          setSearchValue={(value) => setSearchValue(value)}
          isLoading={isLoading}
          handleGetWebhooks={handleGetWebhooks}
          webhookType={webhookType}
          handleChangeWebhookType={handleChangeWebhookType}
          webhookStatus={webhookStatus}
          handleChangeWebhookStatus={handleChangeWebhookStatus}
          handleClearFilters={handleClearFilters}
        />
      </Box>

      {!isLoading && webhooks && webhooks.length === 0 && (
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">Nenhum webhook encontrado.</Typography>
        </Box>
      )}

      {totalCount > 0 && totalCount > perPage && !isLoading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '95%' }}
          p={2}
        >
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(_, newPage) => setPage(newPage)}
            siblingCount={5}
            boundaryCount={10}
          />
          <FormControl>
            <InputLabel id="label-perpage">Por página</InputLabel>
            <Select
              labelId="label-perpage"
              value={perPage}
              label="Por página"
              onChange={(e) => setPerPage(Number(e.target.value))}
              sx={{ width: '100px' }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      {!isLoading && webhooks && webhooks.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>END TO END</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tentativas</TableCell>
                <TableCell>Ver mais</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {webhooks?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{maskDateHour(item.createdAt)}</TableCell>
                  <TableCell>{item.transactionId}</TableCell>
                  <TableCell>{item.receivedPayload.EndToEndId}</TableCell>
                  <TableCell>{item.eventType}</TableCell>
                  <TableCell>
                    {item.status === 'sent' && 'Enviado'}
                    {item.status === 'received' && 'Recebido'}
                    {item.status === 'sending' && 'Enviando'}
                    {item.status === 'error' && 'Erro'}
                  </TableCell>
                  <TableCell>{item.attempts}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClick(item.id)}>
                      <Icon>read_more</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}

      {totalCount > 0 && totalCount > perPage && !isLoading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '95%' }}
          p={2}
        >
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(_, newPage) => setPage(newPage)}
            siblingCount={5}
            boundaryCount={10}
          />
          <FormControl>
            <InputLabel id="label-perpage">Por página</InputLabel>
            <Select
              labelId="label-perpage"
              value={perPage}
              label="Por página"
              onChange={(e) => setPerPage(Number(e.target.value))}
              sx={{ width: '100px' }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </LayoutBase>
  )
}
