import { Api } from '../axios-config'

export interface IAuth {
  email: string
  provider: string
  uid: string
  id: number
  allowPasswordChange: boolean
  name: string
  webhookUrl: string
  balance: number
  suspended: boolean
}

export interface IAuthData {
  data: IAuth
}

export interface IAuthValidate {
  data: IAuth
  success: boolean
}

export interface ILogout {
  success: boolean
  errors: string[]
}

interface IPayloadLogin {
  email: string
  password: string
}

export interface IVFormErrors {
  [key: string]: string
}

const auth = async (payload: IPayloadLogin): Promise<IAuth | Error> => {
  try {
    const { data } = await Api.post<IAuthData>('/auth/sign_in', payload)
    if (data) {
      return data.data
    }

    return new Error('Erro ao logar.')
  } catch (error: any) {
    return new Error(
      ((error as { response: { data: { errors: string[] } } }) || undefined)
        .response?.data?.errors[0] || 'Erro ao logar.'
    )
  }
}

const logout = async (): Promise<ILogout | Error> => {
  try {
    const { data } = await Api.delete<ILogout>('/auth/sign_out')

    if (data.success) {
      return data
    }

    return new Error(data.errors[0])
  } catch (error) {
    return new Error(
      (error as { response: { data: { errors: string[] } } }).response.data
        .errors[0] || 'Erro ao sair.'
    )
  }
}

const validateToken = async () => {
  const response = await Api.get<IAuthValidate>('/auth/validate_token')

  return response
}

const handleSuspend = async () => {
  const { status } = await Api.get('/bank/suspend')
  return status
}

export const AuthService = {
  auth,
  logout,
  validateToken,
  handleSuspend
}
