import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Icon,
  LinearProgress,
  Typography
} from '@mui/material'

import { IWebhook } from '../../interfaces'
import { LayoutBase } from '../../layouts'
import { PanelService } from '../../services/api/panel/PanelServices'
import { maskDateHour, maskReaisNumber } from '../../utils/Format'

export const WebhookDetails: React.FC = () => {
  const [webhook, setWebhook] = useState<IWebhook>({} as IWebhook)
  const [isLoading, setIsLoading] = useState(false)

  const { IdDetails } = useParams()
  const navigate = useNavigate()

  const handleSubmit = () => {
    setIsLoading(true)

    PanelService.reSendWebhook(Number(IdDetails))
      .then(() => {
        setIsLoading(false)
        toast.success('Webhook reenviado com sucesso.')
        navigate('/webhooks')
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error.response.data.msg)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    if (!IdDetails) {
      toast.error('Webhook não encontrado')
      navigate('/webhooks')
      setIsLoading(false)
    } else {
      PanelService.getWebhook(Number(IdDetails)).then((result) => {
        setIsLoading(false)
        if (result.response.status === 200) {
          setWebhook(result.data)
        } else {
          toast.error('Ordem não encontrada')
          navigate('/webhooks')
        }
      })
    }
  }, [IdDetails, navigate])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>webhook</Icon>
          <Typography color="text.primary">Webhooks</Typography>
          <Typography color="text.primary">Detalhes do Webhook</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      {!isLoading && webhook.attempts > 0 && (
        <Box sx={{ marginBottom: 2 }}>
          <Button
            onClick={handleSubmit}
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.light',
              borderRadius: 1,
              paddingX: 2,
              '&:hover': {
                backgroundColor: 'primary.dark'
              }
            }}
          >
            <Icon>send</Icon>&nbsp;Reenviar
          </Button>
        </Box>
      )}

      {!isLoading && webhook && (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
          display="flex"
          justifyContent="center"
          padding={2}
          gap={2}
        >
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Typography variant="h5" textAlign="center">
              Webhook {webhook.id}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Criado em
              </Typography>
              <Typography variant="body2" textAlign="center">
                {maskDateHour(webhook.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                ID da Transação
              </Typography>
              <Typography variant="body2" textAlign="center">
                {webhook.transactionId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Status
              </Typography>
              <Typography variant="body2" textAlign="center">
                {webhook.status === 'sent' && 'Enviado'}
                {webhook.status === 'received' && 'Recebido'}
                {webhook.status === 'sending' && 'Enviando'}
                {webhook.status === 'error' && 'Erro'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Atualizado em
              </Typography>
              <Typography variant="body2" textAlign="center">
                {maskDateHour(webhook.updatedAt)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Tipo de Webhook
              </Typography>
              <Typography variant="body2" textAlign="center">
                {webhook.eventType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Usuário
              </Typography>
              <Typography variant="body2" textAlign="center">
                {webhook.userId}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Tentativas
              </Typography>
              <Typography variant="body2" textAlign="center">
                {webhook.attempts}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
              <Typography variant="subtitle2" textAlign="center">
                Motivo
              </Typography>
              <Typography variant="body2" textAlign="center">
                {webhook.reason}
              </Typography>
            </Grid>
          </Grid>

          {webhook.eventType === 'cashout' && (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography variant="h5" textAlign="center">
                  Webhook Recebido
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Código do Cliente
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.ClientCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    End to End
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.EndToEndId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Tipo de Pagamento
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.PaymentType}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Descrição
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.StatusCode?.Description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    ID do Status
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.StatusCode?.StatusId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Locatário
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.Tenant}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    ID da Transação
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.TransactionId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Tipo de Transação
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.TransactionType}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Tipo de Transação Pix
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.TransactionTypePix}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Urgência
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.receivedPayload?.Urgency}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography variant="h5" textAlign="center">
                  Webhook Enviado
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Sucesso
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.success ? 'Sim' : 'Não'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    End to End
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.end_to_end_id}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Status
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.status === 'paid'
                      ? 'Pago'
                      : 'Pendente'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    ID da Transação
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.transaction_id}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Tipo
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.type}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Valor
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {maskReaisNumber(webhook.sendingPayload?.data.value)}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {webhook.eventType === 'cashin' && (
            <>
              {webhook.status !== 'received' && (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Typography variant="h5" textAlign="center">
                      Webhook Recebido
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo de Transação
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.TransactionType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        ID da Transação
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.TransactionId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Valor
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {maskReaisNumber(webhook.receivedPayload?.Amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        End to End
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.EndToEndId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Identificação da Transação
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.transactionIdentification}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        ID da Transação BrCode
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.transactionIdBRCode}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Typography variant="h6" textAlign="center">
                      Origem
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Banco
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.DebitParty?.Bank}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Agência
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.DebitParty?.Branch}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Conta
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.DebitParty?.Account}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.DebitParty?.PersonType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        TaxID
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.DebitParty?.TaxId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo de Conta
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.DebitParty?.AccountType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Nome
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.DebitParty?.Name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Typography variant="h6" textAlign="center">
                      Destino
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Banco
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.Bank}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Agência
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.Branch}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Conta
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.Account}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.PersonType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        TaxID
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.TaxId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo de Conta
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.AccountType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Nome
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.Name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Chave
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.receivedPayload?.CreditParty?.Key}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Typography variant="h5" textAlign="center">
                  Webhook Enviado
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Sucesso
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.success ? 'Sim' : 'Não'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    End to End
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.end_to_end_id}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Status
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.status === 'paid'
                      ? 'Pago'
                      : 'Pendente'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    ID da Transação
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.transaction_id}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Tipo
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {webhook.sendingPayload?.data.type}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  <Typography variant="subtitle2" textAlign="center">
                    Valor
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    {maskReaisNumber(webhook.sendingPayload?.data.value)}
                  </Typography>
                </Grid>
              </Grid>
              {webhook.status !== 'received' && (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Typography variant="h6" textAlign="center">
                      Origem
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Banco
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.sendingPayload?.data.debit_data?.bank}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Agência
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.sendingPayload?.data.debit_data?.agency}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Conta
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.sendingPayload?.data.debit_data?.account}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Documento
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.sendingPayload?.data.debit_data?.document}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Nome
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {webhook.sendingPayload?.data.debit_data?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      )}
    </LayoutBase>
  )
}
