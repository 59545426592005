import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'

import { ILimit, ILimitUpdate } from '../../interfaces'
import { LimitsService } from '../../services/api/limits/LimitsService'
import { twentyFourHours, weekDays } from './week_days'

interface IDialog {
  isOpen: boolean
  onClose: () => void
  updateLimits: ILimit | null
  reloadLimits: () => void
}

export const ModalUpdate: React.FC<IDialog> = ({
  isOpen,
  onClose,
  updateLimits,
  reloadLimits
}) => {
  const [startWeekDay, setStartWeekDay] = useState(5)
  const [endWeekDay, setEndWeekDay] = useState(1)
  const [startHour, setStartHour] = useState('')
  const [endHour, setEndHour] = useState('')
  const [inUse, setInUse] = useState('')
  const [maxUse, setMaxUse] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleMaxUseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value
    // Certifique-se de que cost é sempre uma string
    const numericValue = rawValue.replace(/[^0-9]/g, '')
    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setMaxUse(formattedValue)
  }
  const handleInUseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value
    // Certifique-se de que cost é sempre uma string
    const numericValue = rawValue.replace(/[^0-9]/g, '')
    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setInUse(formattedValue)
  }

  const handleOnClose = () => {
    onClose()
  }

  const handleSubmit = () => {
    if (!updateLimits) return

    setIsLoading(true)
    const payload: ILimitUpdate = {}

    if (startWeekDay !== updateLimits.startWeekDay) {
      payload.startWeekDay = startWeekDay
    }
    if (endWeekDay !== updateLimits.endWeekDay) {
      payload.endWeekDay = endWeekDay
    }
    if (startHour !== updateLimits.startHour) {
      payload.startHour = startHour
    }
    if (endHour !== updateLimits.endHour) {
      payload.endHour = endHour
    }
    if (inUse !== updateLimits.inUse?.toString()) {
      payload.inUse = Number(inUse.replace(',', '.'))
    }
    if (maxUse !== updateLimits.maxUse?.toString()) {
      payload.maxUse = Number(maxUse.replace(',', '.'))
    }

    LimitsService.update(updateLimits.id, payload).then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        toast.success('Limite atualizado')
        reloadLimits()
        handleOnClose()
      } else {
        toast.error('Erro ao atualizar limite')
      }
    })
  }

  useEffect(() => {
    if (updateLimits) {
      setStartWeekDay(updateLimits.startWeekDay)
      setEndWeekDay(updateLimits.endWeekDay)
      setStartHour(updateLimits.startHour)
      setEndHour(updateLimits.endHour)
      setInUse(updateLimits.inUse?.toString())
      setMaxUse(updateLimits.maxUse?.toString())
    }
  }, [updateLimits])

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          Editar limite de cashout
          <IconButton onClick={handleOnClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 2
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="start-week-day">Dia da semana inicial</InputLabel>
              <Select
                fullWidth
                label="Dia da semana inicial"
                labelId="start-week-day"
                name="startWeekDay"
                value={startWeekDay}
                onChange={(e) => setStartWeekDay(Number(e.target.value))}
              >
                {weekDays.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="end-week-day">Dia da semana final</InputLabel>
              <Select
                fullWidth
                label="Dia da semana final"
                labelId="end-week-day"
                name="endWeekDay"
                value={endWeekDay}
                onChange={(e) => setEndWeekDay(Number(e.target.value))}
              >
                {weekDays.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="start-hour">Hora inicial</InputLabel>
              <Select
                fullWidth
                label="Hora inicial"
                labelId="start-hour"
                name="startHour"
                value={startHour}
                onChange={(e) => setStartHour(e.target.value)}
              >
                {twentyFourHours.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="end-hour">Hora final</InputLabel>
              <Select
                fullWidth
                label="Hora final"
                labelId="end-hour"
                name="endHour"
                value={endHour}
                onChange={(e) => setEndHour(e.target.value)}
              >
                {twentyFourHours.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <TextField
              fullWidth
              label="Em uso"
              name="inUse"
              value={inUse?.replace('.', ',')}
              onChange={handleInUseChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography>R$</Typography>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              fullWidth
              label="Limite"
              value={maxUse?.replace('.', ',')}
              onChange={handleMaxUseChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography>R$</Typography>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Button
            variant="contained"
            disabled={isLoading}
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            ATUALIZAR
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
