import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Breadcrumbs,
  Icon,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { ILimit } from '../../interfaces'
import { LayoutBase } from '../../layouts'
import { LimitsService } from '../../services/api/limits/LimitsService'
import { maskDateHour, maskReaisNumber } from '../../utils/Format'
import { ModalUpdate } from './ModalUpdate'
import { weekDays } from './week_days'

export const Limits: React.FC = () => {
  const [limits, setLimits] = useState<ILimit[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [limitForUpdate, setLimitForUpdate] = useState<ILimit | null>(null)

  const fetchLimits = async () => {
    setIsLoading(true)
    try {
      const response = await LimitsService.index()
      setLimits(response.data)
    } catch (error) {
      toast.error('Erro ao buscar os limites.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdate = (item: ILimit) => {
    setLimitForUpdate(item)
    setIsOpenUpdate(true)
  }

  const handleOnClose = () => {
    setIsOpenUpdate(false)
    setLimitForUpdate(null)
  }

  useEffect(() => {
    fetchLimits()
  }, [])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>assessment</Icon>
          <Typography color="text.primary">Limites</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      {!isLoading && limits && limits.length === 0 && (
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">Nenhum registro encontrado.</Typography>
        </Box>
      )}

      {!isLoading && limits && limits.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>Dia da semana inicial</TableCell>
                <TableCell>Dia da semana final</TableCell>
                <TableCell>Hora inicial</TableCell>
                <TableCell>Hora final</TableCell>
                <TableCell>Em uso</TableCell>
                <TableCell>Máximo</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {limits.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{maskDateHour(item.createdAt)}</TableCell>
                  <TableCell>
                    {
                      weekDays.find((days) => days.value === item.startWeekDay)
                        ?.label
                    }
                  </TableCell>
                  <TableCell>
                    {
                      weekDays.find((days) => days.value === item.endWeekDay)
                        ?.label
                    }
                  </TableCell>
                  <TableCell>{item.startHour + ':00'}</TableCell>
                  <TableCell>{item.endHour + ':00'}</TableCell>
                  <TableCell>{maskReaisNumber(item.inUse)}</TableCell>
                  <TableCell>{maskReaisNumber(item.maxUse)}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleUpdate(item)}>
                      <Icon>edit</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}

      <ModalUpdate
        isOpen={isOpenUpdate}
        onClose={handleOnClose}
        updateLimits={limitForUpdate}
        reloadLimits={fetchLimits}
      />
    </LayoutBase>
  )
}
