import { Dayjs } from 'dayjs'

import {
  IStatement,
  IStatementCreate,
  IStatementIndex
} from '../../../interfaces'
import { Api } from '../axios-config'

const index = async (
  service: string,
  page: number,
  perPage: number,
  startDate: Dayjs,
  endDate: Dayjs,
  operationType: string
) => {
  const response = await Api.get<IStatementIndex>('/statements', {
    params: {
      service: service,
      page: page,
      perPage: perPage,
      startDate: startDate.subtract(3, 'hours'),
      endDate: endDate.subtract(3, 'hours'),
      operationType: operationType
    }
  })

  return response
}

const ofx = async (
  service: string,
  page: number,
  perPage: number,
  startDate: Dayjs,
  endDate: Dayjs,
  operationType: string
) => {
  const response = await Api.get<{ message: string }>(
    '/statements/export_ofx',
    {
      params: {
        service: service,
        page: page,
        perPage: perPage,
        startDate: startDate.subtract(3, 'hours'),
        endDate: endDate.subtract(3, 'hours'),
        operationType: operationType
      }
    }
  )

  return response
}

const show = async (id: number) => {
  const response = await Api.get<IStatement>(`/statements/${id}`)

  return response
}

const create = async (payload: IStatementCreate) => {
  const response = await Api.post<IStatementCreate>('/statements', payload)

  return response
}

const update = async (id: number, payload: IStatementCreate) => {
  const response = await Api.patch<IStatementCreate>(
    `/statements/${id}`,
    payload
  )

  return response
}

const destroy = async (id: number) => {
  const response = await Api.delete(`/statements/${id}`)

  return response
}

export const StatementService = {
  index,
  ofx,
  show,
  create,
  update,
  destroy
}
