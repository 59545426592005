import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
  Typography
} from '@mui/material'

import { TwoFactorService } from '../../services/api/two-factor/twofactorService'
import { ITwoFactor } from './TwoFactor'

interface IDialog {
  isOpen: boolean
  onClose: () => void
  update: ITwoFactor
}

export const ModalUpdate: React.FC<IDialog> = ({ isOpen, onClose, update }) => {
  const [masterKey, setMasterKey] = useState('')
  const [qrcode, setQrcode] = useState('')
  const [codeParts, setCodeParts] = useState(['', '', '', '', '', ''])
  const [isLoading, setIsLoading] = useState(false)
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  const handleOnClose = () => {
    setMasterKey('')
    setQrcode('')
    setCodeParts(['', '', '', '', '', ''])
    onClose()
  }

  const handleCodeChange = (index: number, value: string) => {
    if (value.length <= 1) {
      const newCodeParts = [...codeParts]
      newCodeParts[index] = value
      setCodeParts(newCodeParts)

      // Move to next input if not empty and next exists
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus()
      }
      // Move to previous input if empty and previous exists
      else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus()
      }
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)

    const payload = {
      code: codeParts.join('')
    }

    TwoFactorService.update(update.id, payload)
      .then((result) => {
        setIsLoading(true)
        if (result.status === 200) {
          setIsLoading(false)
          toast.success('2FA Ativo com sucesso.')
          handleOnClose()
        } else {
          setIsLoading(false)
          toast.error('Erro ao ativar 2FA.')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error(err.response.data.code[0])
      })
  }

  useEffect(() => {
    if (update) {
      setMasterKey(update.masterKey)
      setQrcode(update.qrcode)
    }
  }, [update])

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          Ativar 2FA
          <IconButton onClick={handleOnClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
            padding: 5
          }}
        >
          <Avatar
            src={`data:image/png;base64,${qrcode}`}
            variant="square"
            sx={{ width: '300px', height: '300px' }}
          />
          <Box>
            <Typography>
              Para ativação manual use os seguintes dados:
            </Typography>
            <Typography>
              Chave: <strong>{masterKey}</strong>
            </Typography>
            <Typography>
              Tipo de chave: <strong>Baseada em horário</strong>
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <Typography>Código de autenticação</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {codeParts.map((part, index) => (
                <TextField
                  key={index}
                  value={part}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center' }
                  }}
                  inputRef={(ref) => (inputRefs.current[index] = ref)}
                />
              ))}
            </Box>
          </Box>
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.light',
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Ativar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
