import { Dayjs } from 'dayjs'

import { Search } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { IFilter } from './IFilter'
import { orderTypes } from './order_types'

export const Filter: React.FC<IFilter> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  searchValue,
  setSearchValue,
  isLoading,
  handleGetOrders,
  orderType,
  handleChangeOrderType,
  orderStatus,
  handleChangeOrderStatus,
  handleClearFilters,
  handleExportCSV
}) => {
  const handleChangeStartDate = (value: Dayjs | null) => {
    if (value) {
      setStartDate(value.startOf('day'))
    }
  }

  const handleChangeEndDate = (value: Dayjs | null) => {
    if (value) {
      setEndDate(value.endOf('day'))
    }
  }
  return (
    <Box
      sx={{
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        p: 2
      }}
    >
      <FormControl
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2
        }}
      >
        <DatePicker
          label="Data Inicial"
          value={startDate}
          disableFuture
          onChange={handleChangeStartDate}
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
        <DatePicker
          label="Data Final"
          value={endDate}
          disableFuture
          onChange={handleChangeEndDate}
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
      </FormControl>

      <TextField
        fullWidth
        label="Pesquisar"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value.trim())}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                color="primary"
                aria-label="toggle password visibility"
                onClick={handleGetOrders}
                disabled={isLoading}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <FormControl fullWidth>
        <InputLabel id="label-order-type">Tipo de ordem</InputLabel>
        <Select
          size="small"
          value={orderType}
          defaultValue="todos"
          label="Tipo de ordem"
          labelId="label-order-type"
          onChange={handleChangeOrderType}
        >
          {orderTypes.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="label-order-status">Status</InputLabel>
        <Select
          size="small"
          value={orderStatus}
          defaultValue="todos"
          label="Status"
          labelId="label-order-status"
          onChange={handleChangeOrderStatus}
        >
          <MenuItem value="todos"> TODOS </MenuItem>
          <MenuItem value="pending"> PENDENTE </MenuItem>
          <MenuItem value="paid"> PAGO </MenuItem>
          <MenuItem value="cancelled"> CANCELADO </MenuItem>
        </Select>
      </FormControl>

      <Tooltip title="Exportar CSV">
        <Button
          variant="contained"
          startIcon={<Icon>file_download_outlined</Icon>}
          onClick={handleExportCSV}
          disabled={isLoading}
        >
          CSV
        </Button>
      </Tooltip>

      <Tooltip title="Limpar filtros">
        <IconButton
          onClick={handleClearFilters}
          disabled={isLoading}
          color="primary"
        >
          <Icon>delete_outline</Icon>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
