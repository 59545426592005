import { useState } from 'react'
import { toast } from 'react-toastify'

import * as yup from 'yup'

import {
  Box,
  Button,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { LayoutBase } from '../../layouts'
import {
  IReturnGenerateQrCode,
  PanelService
} from '../../services/api/panel/PanelServices'

const QrCodeSchema = yup.object().shape({
  value: yup.string().min(3).max(13).required(),
  additionalInformation: yup.string().max(50)
})

export const GenerateQrCode: React.FC = () => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const [dataQRCode, setDataQRCode] = useState<IReturnGenerateQrCode>()
  const [copiedText, setCopiedText] = useState('')
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const [value, setValue] = useState('')
  const [additionalInformation, setAdditionalInformation] = useState('')
  const [valueError, setValueError] = useState('')
  const [additionalInformationError, setAdditionalInformationError] =
    useState('')

  const handleSubmit = () => {
    setIsLoading(true)
    const unformattedValue = value.replace(/[^0-9]/g, '') // Remove caracteres não numéricos

    // Limita o número de dígitos inteiros a 10 e o número de dígitos decimais a 2
    const limitedValue = unformattedValue.slice(0, 10) // Obtém os 10 primeiros dígitos
    const integerPart = limitedValue.slice(0, -2) // Obtém a parte inteira (todos os dígitos, exceto os 2 últimos)
    const decimalPart = limitedValue.slice(-2) // Obtém os 2 últimos dígitos decimais
    const formatted = `${integerPart}.${decimalPart}` // Formata a parte inteira e decimal separados por um ponto

    QrCodeSchema.validate(
      { value: formatted, additionalInformation },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        PanelService.generate(dataValidated).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            toast.error(result.message)
          } else {
            toast.success('QRCode gerado com sucesso!')
            setShowQRCode(true)
            setDataQRCode(result)
            setCopiedText(result.emv)
          }
        })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'value') {
            setValueError(error.message)
          } else if (error.path === 'additionalInformation') {
            setAdditionalInformationError(error.message)
          }
        })
      })
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(copiedText)
    toast.success('Pix copia e cola copiado!')
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value

    // Remove os caracteres não numéricos
    const numericValue = rawValue.replace(/[^0-9]/g, '')

    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setValue(formattedValue)
  }

  return (
    <LayoutBase>
      <Box
        gap={2}
        marginY={2}
        padding={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
        width={theme.spacing(smDown ? 44 : mdDown ? 44 : 70)}
      >
        {showQRCode && (
          <>
            <Typography sx={{ color: 'text.primary' }} variant="h6">
              QR Code gerado.
            </Typography>
            <Tooltip title="Clique para copiar">
              <Typography
                variant="inherit"
                onClick={handleCopyClick}
                textAlign="center"
                style={{
                  cursor: 'pointer',
                  overflowWrap: 'anywhere'
                }}
              >
                {copiedText}
              </Typography>
            </Tooltip>
            <img
              width={theme.spacing(smDown ? 36 : mdDown ? 36 : 60)}
              alt="QRCode"
              src={`data:image/png;base64,${dataQRCode?.qrcodeInBase64}`}
            />
          </>
        )}

        {!showQRCode && (
          <>
            <Typography sx={{ color: 'text.primary' }} variant="h6">
              Gerar QrCode
            </Typography>
            <TextField
              fullWidth
              label="Valor"
              value={value}
              disabled={isLoading}
              error={!!valueError}
              helperText={valueError}
              onKeyDown={() => setValueError('')}
              onChange={handleValueChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography>R$</Typography>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              fullWidth
              label="Descrição"
              value={additionalInformation}
              disabled={isLoading}
              error={!!additionalInformationError}
              helperText={additionalInformationError}
              onKeyDown={() => setAdditionalInformationError('')}
              onChange={(e) => setAdditionalInformation(e.target.value)}
            />
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress color="primary" />
              </Box>
            )}
            {!isLoading && (
              <Button
                fullWidth
                onClick={handleSubmit}
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'primary.light',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: 'primary.dark'
                  }
                }}
              >
                Continuar
              </Button>
            )}
          </>
        )}
      </Box>
    </LayoutBase>
  )
}
