import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress
} from '@mui/material'

import { AuthService } from '../../services/api/auth/AuthService'
import { maskReaisNumber } from '../../utils/Format'

export const Balance: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [balance, setBalance] = useState(0)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const getBalance = useCallback(() => {
    setIsLoading(true)
    AuthService.validateToken().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        if (result.data.data.balance !== balance) {
          setBalance(result.data.data.balance)
        }
      } else {
        toast.error('Erro ao Buscar Saldo')
      }
    })
  }, [balance])

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      marginY={2}
      padding={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      sx={{ backgroundColor: 'primary.dark', borderRadius: 1 }}
      width={theme.spacing(smDown ? 36 : mdDown ? 40 : 50)}
    >
      <Typography sx={{ color: 'primary.light' }} variant="h6">
        Saldo Disponível
      </Typography>
      <Box
        padding={1}
        sx={{ backgroundColor: 'primary.main', borderRadius: 1 }}
      >
        <Box
          gap={2}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          width={theme.spacing(smDown ? 32 : mdDown ? 36 : 46)}
        >
          <Grid container spacing={2}>
            <Grid
              item
              display="flex"
              alignItems="center"
              justifyContent="center"
              xs={10}
            >
              <Typography sx={{ color: 'primary.light', fontSize: '24px' }}>
                <strong>
                  {!isLoading ? (
                    maskReaisNumber(balance)
                  ) : (
                    <CircularProgress color="secondary" />
                  )}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
