import { useState } from 'react'
import { toast } from 'react-toastify'

import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { useAuthContext } from '../../../contexts'
import { LayoutBase } from '../../../layouts'
import {
  IConsultKey,
  IStatementPix,
  PanelService
} from '../../../services/api/panel/PanelServices'
import { maskCNPJ, maskCPF, maskPhone } from '../../../utils/Format'

const PixSchema = yup.object().shape({
  key: yup.string().min(3).max(50).required(),
  keyType: yup.string().required()
})

const SendPixSchema = yup.object().shape({
  key: yup.string().min(3).max(50).required(),
  value: yup.string().min(3).max(50).required(),
  paymentId: yup.string().required(),
  name: yup.string().required(),
  document: yup.string().required(),
  ispb: yup.string().required(),
  accountNumber: yup.string().required(),
  branch: yup.string().required(),
  accountType: yup.string().required()
})

export const Pix = () => {
  const theme = useTheme()
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [keyError, setKeyError] = useState('')
  const [valueError, setValueError] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectValue, setSelectValue] = useState('')
  const [showSendPix, setShowSendPix] = useState(false)
  const [selectValueError, setSelectValueError] = useState('')
  const [dataSendPix, setDataSendPix] = useState<IStatementPix>(
    {} as IStatementPix
  )
  const [dataConsultKey, setDataConsultKey] = useState<IConsultKey>(
    {} as IConsultKey
  )

  const { user } = useAuthContext()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const handleBack = () => {
    setIsError(false)
    setActiveStep(activeStep - 1)
    if (activeStep === 1) setDataConsultKey({} as IConsultKey)
  }

  const handleSubmitKey = () => {
    setIsLoading(true)
    const newKey = selectValue === 'phone' ? `+55${key}` : key
    PixSchema.validate({ key: newKey, document }, { abortEarly: false })
      .then((dataValidated) => {
        PanelService.consultKey(dataValidated).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            toast.error(result.message)
            setIsError(true)
          } else {
            setDataConsultKey(result)
          }
        })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'key') {
            setKeyError(error.message)
          } else if (error.path === 'selectValue') {
            setSelectValueError(error.message)
          }
        })
      })
  }

  const handleSubmitPix = () => {
    setIsLoading(true)
    const paymentId = uuidv4()
    const key = dataConsultKey.key
    const name = dataConsultKey.name
    const document = dataConsultKey.document
    const ispb = dataConsultKey.participant.ispb
    const accountNumber = dataConsultKey.accountNumber
    const branch = dataConsultKey.branch
    const accountType = dataConsultKey.accountType

    const unformattedValue = value.replace(/[^0-9]/g, '') // Remove caracteres não numéricos
    const limitedValue = unformattedValue.slice(0, 10) // Obtém os 10 primeiros dígitos
    const integerPart = limitedValue.slice(0, -2) // Obtém a parte inteira (todos os dígitos, exceto os 2 últimos)
    const decimalPart = limitedValue.slice(-2) // Obtém os 2 últimos dígitos decimais
    const formatted = `${integerPart}.${decimalPart}` // Formata a parte inteira e decimal separados por um ponto

    SendPixSchema.validate(
      {
        key,
        name,
        ispb,
        value: formatted,
        branch,
        document,
        paymentId,
        accountType,
        accountNumber
      },
      { abortEarly: false }
    )
      .then((dataValidated) => {
        PanelService.sendPix(dataValidated).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            toast.error(result.message)
          } else {
            toast.success('PIX realizado com sucesso!')
            setDataSendPix(result)
            setShowSendPix(true)
          }
        })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'value') {
            setValueError(error.message)
          }
        })
      })
  }

  const handleNext = () => {
    if (activeStep === 0) {
      handleSubmitKey()
      if (key && selectValue) {
        setActiveStep(activeStep + 1)
      }
    } else if (activeStep === 2) {
      if (value) {
        handleSubmitPix()
      }
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value

    // Remove os caracteres não numéricos
    const numericValue = rawValue.replace(/[^0-9]/g, '')

    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setValue(formattedValue)
  }

  const applyMask = (value: string) => {
    if (selectValue === 'cpf') {
      return maskCPF(value)
    } else if (selectValue === 'cnpj') {
      return maskCNPJ(value)
    } else if (selectValue === 'phone') {
      return maskPhone(value)
    } else if (selectValue === 'email') {
      return value
    } else if (selectValue === 'random') {
      return value
    }
    return value
  }

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}

            {!isLoading && (
              <>
                <Typography variant="h6">Digite a chave PIX:</Typography>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="typeKey">Tipo de Chave</InputLabel>
                  <Select
                    id="typeKey"
                    value={selectValue}
                    error={!!selectValueError}
                    onKeyDown={() => setSelectValueError('')}
                    input={<OutlinedInput label="Tipo de Chave" />}
                    onChange={(e) => setSelectValue(e.target.value)}
                    MenuProps={{
                      PaperProps: { sx: { backgroundColor: '#ffffff' } }
                    }}
                  >
                    <MenuItem value="cpf">CPF</MenuItem>
                    <MenuItem value="cnpj">CNPJ</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phone">Celular</MenuItem>
                    <MenuItem value="random">Aleatória</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  sx={{ width: 300 }}
                  label="Chave Pix"
                  value={applyMask(key)}
                  error={!!keyError}
                  helperText={keyError}
                  onKeyDown={() => setKeyError('')}
                  onChange={(e) => setKey(e.target.value)}
                />
              </>
            )}
          </>
        )
      case 1:
        return (
          <>
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}

            {!isLoading && !isError && (
              <>
                <Typography variant="h6">Confirme os dados</Typography>
                <Grid
                  container
                  display="flex"
                  paddingLeft={2}
                  alignItems="left"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography>
                    <strong>Nome: </strong>
                    {dataConsultKey.name}
                  </Typography>
                  <Typography>
                    <strong>Documento: </strong>
                    {dataConsultKey?.document?.length === 11
                      ? maskCPF(dataConsultKey.document)
                      : maskCNPJ(dataConsultKey.document)}
                  </Typography>
                  <Typography>
                    <strong>Instituição: </strong>
                    {dataConsultKey.participant.name}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )
      case 2:
        return (
          <>
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}

            {!isLoading && (
              <>
                <Typography variant="h6" paddingLeft={2}>
                  Digite o valor que deseja transferir para{' '}
                  {dataConsultKey.name}
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  label="Valor"
                  value={value}
                  disabled={isLoading}
                  error={!!valueError}
                  helperText={valueError}
                  onKeyDown={() => setValueError('')}
                  onChange={handleValueChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>R$</Typography>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField fullWidth type="text" label="Descrição (opcional)" />
              </>
            )}
          </>
        )
      default:
        return null
    }
  }

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>payments</Icon>
          <Typography color="text.primary">Sacar</Typography>
          <Typography color="text.primary">PIX</Typography>
        </Breadcrumbs>
      </Box>
      <Box
        gap={2}
        marginY={2}
        padding={1}
        display="flex"
        color="text.primary"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
        width={theme.spacing(smDown ? 38 : mdDown ? 44 : 70)}
      >
        {showSendPix && (
          <>
            <Typography>Transação: {dataSendPix?.transactionId}</Typography>

            <Divider sx={{ width: '100%' }} />

            <Grid
              container
              display="flex"
              paddingLeft={2}
              alignItems="left"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>
                <strong>Destinatário</strong>
              </Typography>
              <Typography>{dataConsultKey.name}</Typography>
              <Typography>
                Documento:&nbsp;
                {dataConsultKey?.document?.length === 11
                  ? maskCPF(dataConsultKey.document)
                  : maskCNPJ(dataConsultKey.document)}
              </Typography>
              <Typography>
                Instituição: {dataConsultKey.participant.name}
              </Typography>
              <Typography> </Typography>
            </Grid>
            <Grid
              container
              display="flex"
              paddingLeft={2}
              alignItems="left"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>
                <strong>Origem</strong>
              </Typography>
              <Typography>{user?.name}</Typography>
              {/* <Typography>
                Documento:&nbsp;
                {user?.document.length === 11
                  ? maskCPF(user?.document || '')
                  : maskCNPJ(user?.document || '')}
              </Typography> */}
              <Typography>Instituição: Li Banco Multipag</Typography>
              <Typography> </Typography>
            </Grid>

            <Divider sx={{ width: '100%' }} />

            <Grid
              container
              display="flex"
              paddingLeft={2}
              alignItems="left"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>
                <strong>ID Pix</strong>
              </Typography>
              <Typography> {dataSendPix?.endToEndId} </Typography>
              <Typography> </Typography>
            </Grid>

            <Divider sx={{ width: '100%' }} />
            <Divider sx={{ width: '100%' }} />

            <Grid
              container
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item display="flex" justifyContent="left" xs={6}>
                <Typography variant="h6"> Valor: </Typography>
              </Grid>
              <Grid item display="flex" justifyContent="right" xs={6}>
                <Typography variant="h6"> R$ {value} </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {!showSendPix && (
          <>
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel>Chave PIX</StepLabel>
              </Step>
              <Step>
                <StepLabel>Confirmar dados</StepLabel>
              </Step>
              <Step>
                <StepLabel>Enviar Pix</StepLabel>
              </Step>
            </Stepper>

            {renderStepContent(activeStep)}

            <Grid
              container
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item display="flex" justifyContent="left" xs={6}>
                {activeStep === 0 ? (
                  ''
                ) : (
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'primary.light',
                      borderRadius: 1,
                      '&:hover': {
                        backgroundColor: 'primary.dark'
                      }
                    }}
                  >
                    Voltar
                  </Button>
                )}
              </Grid>
              <Grid item display="flex" justifyContent="right" xs={6}>
                <Button
                  variant="contained"
                  disabled={isLoading || isError}
                  onClick={handleNext}
                  endIcon={
                    isLoading ? <CircularProgress size={20} /> : undefined
                  }
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.light',
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'primary.dark'
                    }
                  }}
                >
                  {activeStep === 2 ? 'Enviar' : 'Avançar'}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </LayoutBase>
  )
}
