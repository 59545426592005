import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import * as yup from 'yup'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
  useTheme
} from '@mui/material'

import { useAuthContext } from '../../contexts'

const LoginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required()
})

interface ILoginProps {
  children?: React.ReactNode
}

export const Login: React.FC<ILoginProps> = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const [isLoading, setIsLoading] = useState(false)
  const { login } = useAuthContext()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handleSubmit = () => {
    setIsLoading(true)
    LoginSchema.validate({ email, password }, { abortEarly: false })
      .then((dataValidated) => {
        login(dataValidated.email, dataValidated.password).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            toast.error(result.message)
          } else {
            toast.success(result)
          }
        })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        errors.inner.forEach((error) => {
          if (error.path === 'email') {
            setEmailError(error.message)
          } else if (error.path === 'password') {
            setPasswordError(error.message)
          }
        })
      })
  }

  useEffect(() => {
    const accountConfirmation = urlParams.get('account_confirmation_success')
    if (accountConfirmation === 'true') {
      toast.success('Conta confirmada com sucesso!')
    }

    if (accountConfirmation === 'false') {
      toast.error('Página não encontrada.')
    }

    return () => {
      urlParams.delete('account_confirmation_success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box height="100vh" width="100vw">
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        sx={{ backgroundColor: 'background.default' }}
      >
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            gap={4}
            marginX={2}
            paddingX={2}
            display="flex"
            borderRadius={5}
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            width={theme.spacing(53)}
            sx={{ backgroundColor: '#ffffff' }}
          >
            <Avatar
              src="logo.png"
              variant="rounded"
              alt="Logo"
              sx={{ width: 200, height: 100 }}
            />
            <Typography sx={{ color: 'text.secondary' }} variant="h4">
              Acesse o Painel
            </Typography>
            <TextField
              fullWidth
              value={email}
              label="Email"
              disabled={isLoading}
              error={!!emailError}
              helperText={emailError}
              onKeyDown={() => setEmailError('')}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label="Senha"
              value={password}
              disabled={isLoading}
              error={!!passwordError}
              helperText={passwordError}
              onKeyDown={() => setPasswordError('')}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      color="primary"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {isLoading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress color="primary" />
              </Box>
            )}
            {!isLoading && (
              <Button
                fullWidth
                onClick={handleSubmit}
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'primary.light',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: 'primary.dark'
                  }
                }}
              >
                Continuar
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
