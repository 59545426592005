import React from 'react'

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'

import { Balance, ChartOrder, ChartWebhook } from '../../shared/components'
import { LayoutBase } from '../../shared/layouts/LayoutBase'

export const Dashboard: React.FC = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <LayoutBase>
      <Box>
        <Balance />

        <Typography variant="h5" textAlign="center" color="primary.main">
          Gráficos
        </Typography>

        <Box
          sx={{
            width: theme.spacing(smDown ? 36 : mdDown ? 40 : 50),
            display: 'flex',
            justifyContent: 'center',
            flexDirection: smDown ? 'column' : mdDown ? 'column' : 'row'
          }}
        >
          <ChartOrder />
          <ChartWebhook />
        </Box>
      </Box>
    </LayoutBase>
  )
}
