import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { PieChart } from '@mui/x-charts'

import { IWebhooksChart } from '../../interfaces'
import { PanelService } from '../../services/api/panel/PanelServices'

export const ChartWebhook: React.FC = () => {
  const [webhooks, setWebhooks] = useState<IWebhooksChart>({} as IWebhooksChart)
  const [isLoading, setIsLoading] = useState(true)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const getWebhooks = useCallback(() => {
    setIsLoading(true)
    PanelService.getWebhooksChart().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        if (result.data !== webhooks) {
          setWebhooks(result.data)
        }
      } else {
        toast.error('Erro ao Buscar Charts')
      }
    })
  }, [webhooks])

  useEffect(() => {
    getWebhooks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Typography variant="h6" textAlign="center" color="primary.main">
        Webhooks
      </Typography>
      {!isLoading ? (
        <>
          <PieChart
            series={[
              {
                data: [
                  {
                    id: 0,
                    value: webhooks.sent,
                    color: '#20B2AA'
                  },
                  {
                    id: 1,
                    value: webhooks.sending,
                    color: '#5F9EA0'
                  },
                  {
                    id: 2,
                    value: webhooks.received,
                    color: '#708090'
                  },
                  {
                    id: 3,
                    value: webhooks.error,
                    color: '#ff2800'
                  }
                ],
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: {
                  innerRadius: 0,
                  additionalRadius: -30,
                  color: 'gray'
                }
              }
            ]}
            width={smDown ? 400 : mdDown ? 400 : 400}
            height={smDown ? 200 : mdDown ? 200 : 200}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center'
            }}
          >
            <Box
              sx={{ width: '1rem', height: '1rem', backgroundColor: '#20B2AA' }}
            ></Box>
            <Typography variant="body1" color="primary.main">
              Enviado: {webhooks.sent}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center'
            }}
          >
            <Box
              sx={{ width: '1rem', height: '1rem', backgroundColor: '#5F9EA0' }}
            ></Box>
            <Typography variant="body1" color="primary.main">
              Enviando: {webhooks.sending}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center'
            }}
          >
            <Box
              sx={{ width: '1rem', height: '1rem', backgroundColor: '#708090' }}
            ></Box>
            <Typography variant="body1" color="primary.main">
              Recebidos: {webhooks.received}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center'
            }}
          >
            <Box
              sx={{ width: '1rem', height: '1rem', backgroundColor: '#ff2800' }}
            ></Box>
            <Typography variant="body1" color="primary.main">
              Erros: {webhooks.error}
            </Typography>
          </Box>
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Box>
  )
}
