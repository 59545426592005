import React from 'react'
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'

import {
  Avatar,
  Box,
  Drawer,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { useAuthContext, useDrawerContext } from '../../contexts'

interface IListItemLinkProps {
  to: string
  icon: string
  label: string
  onClick: (() => void) | undefined
}

const ListItemLink: React.FC<IListItemLinkProps> = ({
  to,
  icon,
  label,
  onClick
}) => {
  const navigate = useNavigate()
  const resolvedPath = useResolvedPath(to)
  const match = useMatch({ path: resolvedPath.pathname, end: false })

  const handleClick = () => {
    navigate(to)
    onClick?.()
  }

  return (
    <ListItemButton
      selected={!!match}
      onClick={handleClick}
      sx={{
        color: 'text.secondary',
        '&:hover': { backgroundColor: 'secondary.dark', borderLeft: 5 },
        '&.Mui-selected': { backgroundColor: 'secondary.dark', borderLeft: 5 }
      }}
    >
      <ListItemIcon>
        <Icon sx={{ color: 'text.secondary' }}>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

type LeftSideProps = {
  children?: React.ReactNode
}

export const LeftSide: React.FC<LeftSideProps> = ({ children }) => {
  const theme = useTheme()
  const { logout } = useAuthContext()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { isDrawerOpen, toggleDrawerOpen, drawerOptions } = useDrawerContext()

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        variant={smDown ? 'temporary' : 'permanent'}
        onClose={toggleDrawerOpen}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            width="100%"
            height={theme.spacing(12)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar
              src="logo.png"
              variant="rounded"
              alt="Logo"
              sx={{ width: theme.spacing(24), height: 90 }}
            />
          </Box>

          <Box flex={1}>
            <List component="nav">
              {drawerOptions.map((drawerOption) => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={smDown ? toggleDrawerOpen : undefined}
                />
              ))}
              <ListItemButton
                onClick={logout}
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                    borderLeft: 5
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.dark',
                    borderLeft: 5
                  }
                }}
              >
                <ListItemIcon>
                  <Icon sx={{ color: 'text.secondary' }}>logout</Icon>
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </Drawer>

      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        {children}
      </Box>
    </>
  )
}
