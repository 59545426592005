import { privacyPolicy } from './privacyPolicy'
import { termOfUse } from './termOfUse'

export const Environment = {
  URL_BASE: 'https://hub.bankopay.com.br',

  TERMS_OF_USE: termOfUse,

  POLICY_OF_PRIVACY: privacyPolicy
}
