import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { PieChart } from '@mui/x-charts'

import { IOrdersChart } from '../../interfaces'
import { PanelService } from '../../services/api/panel/PanelServices'

export const ChartOrder: React.FC = () => {
  const [orders, setOrders] = useState<IOrdersChart>({} as IOrdersChart)
  const [isLoading, setIsLoading] = useState(true)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const getOrders = useCallback(() => {
    setIsLoading(true)
    PanelService.getOrdersChart().then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        if (result.data !== orders) {
          setOrders(result.data)
        }
      } else {
        toast.error('Erro ao Buscar Charts')
      }
    })
  }, [orders])

  useEffect(() => {
    getOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Typography variant="h6" textAlign="center" color="primary.main">
        Ordens
      </Typography>
      {!isLoading ? (
        <>
          <PieChart
            series={[
              {
                data: [
                  {
                    id: 0,
                    value: orders.paid,
                    color: '#00FF00'
                  },
                  {
                    id: 1,
                    value: orders.pending,
                    color: '#FFFF00'
                  },
                  {
                    id: 2,
                    value: orders.cancelled,
                    color: '#FF0000'
                  }
                ],
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: {
                  innerRadius: 0,
                  additionalRadius: -30,
                  color: 'gray'
                }
              }
            ]}
            width={smDown ? 400 : mdDown ? 400 : 400}
            height={smDown ? 200 : mdDown ? 200 : 200}
          />
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  width: '1rem',
                  height: '1rem',
                  backgroundColor: '#00FF00'
                }}
              ></Box>
              <Typography variant="body1" color="primary.main">
                Pagos: {orders.paid}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  width: '1rem',
                  height: '1rem',
                  backgroundColor: '#FFFF00'
                }}
              ></Box>
              <Typography variant="body1" color="primary.main">
                Pendentes: {orders.pending}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  width: '1rem',
                  height: '1rem',
                  backgroundColor: '#FF0000'
                }}
              ></Box>
              <Typography variant="body1" color="primary.main">
                Cancelados: {orders.cancelled}
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Box>
  )
}
