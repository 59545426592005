/* eslint-disable no-param-reassign */
import { InternalAxiosRequestConfig } from 'axios'

export const requestInterceptor = (request: InternalAxiosRequestConfig) => {
  if (request.url === '/auth/sign_in') return request

  // request.headers = {
  //     ...request.headers,
  //     uid: localStorage.getItem('uid') || '',
  //     client: localStorage.getItem('client') || '',
  //     accessToken: localStorage.getItem('accessToken') || 'não encontrado',
  //     expiry: localStorage.getItem('expiry') || '',
  //     authorization: `Bearer ${localStorage.getItem('accessToken')}`
  // }

  request.headers.Authorization = localStorage.getItem('Authorization')
  return request
}
