import { IFees, IFeesCreate } from '../../../interfaces'
import { Api } from '../axios-config'

const index = async () => {
  const response = await Api.get<IFees[]>('/fees')

  return response
}

const show = async (id: number) => {
  const response = await Api.get<IFees>(`/fees/${id}`)

  return response
}

const create = async (payload: IFeesCreate) => {
  const response = await Api.post<IFeesCreate>('/fees', payload)

  return response
}

const update = async (id: number, payload: IFeesCreate) => {
  const response = await Api.patch<IFeesCreate>(`/fees/${id}`, payload)

  return response
}

const destroy = async (id: number) => {
  const response = await Api.delete(`/fees/${id}`)

  return response
}

export const FeesService = {
  index,
  show,
  create,
  update,
  destroy
}
