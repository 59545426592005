import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import PropTypes from 'prop-types'

import { AuthService, IAuth } from '../services/api/auth/AuthService'

interface IAuthContextData {
  login: (email: string, password: string) => Promise<string | Error>
  logout: () => Promise<string | Error>
  isAuthenticated: boolean
  user?: IAuth
}

interface IAuthProviderProps {
  children: React.ReactNode
}

const AuthContext = createContext({} as IAuthContextData)

export const LOCAL_STORAGE_USER_DATA = '__id__'

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IAuth>()

  useEffect(() => {
    const localUser = localStorage.getItem(LOCAL_STORAGE_USER_DATA)
    if (localUser) {
      setUser(JSON.parse(localUser))
    }
  }, [])

  const handleLogin = useCallback(async (email: string, password: string) => {
    const result = await AuthService.auth({ email, password })
    if (result instanceof Error) {
      return result
    } else {
      localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(result))
      setUser(result)
      return 'Login realizado com sucesso!'
    }
  }, [])

  const handleLogout = useCallback(async () => {
    const result = await AuthService.logout()
    if (result instanceof Error) {
      return result
    } else {
      localStorage.clear()
      setUser(undefined)
      return 'Desconectado com sucesso'
    }
  }, [])

  const isAuthenticated = useMemo(() => !!user, [user])

  return (
    <AuthContext.Provider
      value={{
        user: user,
        isAuthenticated,
        login: handleLogin,
        logout: handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
